import axios from "axios";
import { getAuthInfo, isAuthInfoPresent } from "@icarius-pages/login/sessionStorage";
import * as endpoints from "./endpoints";

axios.interceptors.request.use(
  (config) => {
    if (isAuthInfoPresent()) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: "Bearer " + getAuthInfo().token,
        },
      };
    }

    return config;
  }
);

export const loginAPI = (data) => axios.post(endpoints.LOGIN_ENDPOINT, data);
export const logoutAPI = (data) => axios.post(endpoints.LOGOUT_ENDPOINT, data);
export const registerAssistanceTotemAPI = (data) => axios.post(endpoints.REGISTER_ASSISTANCE_TOTEM_ENDPOINT, data);

export const getVersionAPI = () => axios.get(endpoints.VERSION_ENDPOINT);

export const forgotPasswordAPI = (data) => axios.post(endpoints.FORGOT_PASSWORD_ENDPOINT, data);
export const resetPasswordAPI = (data) => axios.post(endpoints.RESET_PASSWORD_ENDPOINT, data);

export const getInitialDataAPI = (query) => axios.get(endpoints.INITIAL_DATA_ENDPOINT + query);
export const getHomeDataAPI = () => axios.get(endpoints.HOME_ENDPOINT);

export const getMyPeopleAPI = (query) => axios.get(endpoints.MY_PEOPLE_ENDPOINT + query);
export const getDirectoryAPI = () => axios.get(endpoints.DIRECTORY_ENDPOINT);

export const likeEmployeeAPI = (data) => axios.put(endpoints.LIKES_ENDPOINT, data);

// alerts
export const getAlertsAPI = () => axios.get(endpoints.ALERTS_ENDPOINT);
export const modifyAlertStatusAPI = (data) => axios.put(endpoints.ALERTS_ENDPOINT, data);
export const deleteAlertsAPI = (data) => axios.delete(endpoints.ALERTS_ENDPOINT, { data });


export const getAvailableColors = () => axios.get(endpoints.AVAILABLE_COLORS_ENDPOINT);

export const getPollAPI = (query) => axios.get(endpoints.POLL_ENDPOINT + query);
export const answerPollAPI = (data) => axios.post(endpoints.ANSWER_POLL_ENDPOINT, data);

export const setAppColor = (color) => axios.put(endpoints.SET_APP_COLOR_ENDPOINT, { color_id: color });

export const changePasswordAPI = (data) => axios.put(endpoints.CHANGE_PASSWORD_ENDPOINT, data);

export const getLogoApi = (query) => axios.get(endpoints.GET_LOGO_ENDPOINT + query)

// templates
export const getTemplatesAPI = (query) => axios.get(endpoints.TEMPLATES_ENDPOINT + query);
export const createTemplateAPI = (data) => axios.post(endpoints.TEMPLATES_ENDPOINT, data);
export const updateTemplateAPI = (data) => axios.put(endpoints.TEMPLATES_ENDPOINT, data);
export const deleteTemplateAPI = (data) => axios.delete(endpoints.TEMPLATES_ENDPOINT, { data });

// Poll review
export const getPollReviewAPI = (query) => axios.get(endpoints.POLL_REVIEW_ENDPOINT + query);
export const getPollReviewQuestionsAPI = (query) => axios.get(endpoints.POLL_REVIEW_QUESTIONS_ENDPOINT + query);
export const getPollReviewAnswersAPI = (query) => axios.get(endpoints.POLL_REVIEW_ANSWERS_ENDPOINT + query);
export const downloadPollReviewAPI = (data) => axios.post(endpoints.DOWNLOAD_POLL_REVIEW_ENDPOINT, data, {
  responseType: "blob",
});
export const executePollReviewAPI = (data) => axios.post(endpoints.EXECUTE_POLL_REVIEW_ENDPOINT, data);

//Asistencia
export const getAssistance = () => axios.get(endpoints.GET_ASSISTANCE_ENDPOINT);
export const completeAssistanceMark = (data) => axios.post(endpoints.COMPLETE_ASSISTANCE_MARK_ENDPOINT, data);
export const getMarksForMapAPI = (data) => axios.post(endpoints.GET_ASSISTANCE_MARKS_MAP_ENDPOINT, data);
export const getMyAssistance = () => axios.get(endpoints.GET_MY_ASSISTANCE_ENDPOINT);

//Datos personales
export const getMyPersonalDataAPI = () => axios.get(endpoints.GET_MY_PERSONAL_DATA_ENDPOINT);
export const updateMyPersonalDataAPI = data => axios.put(endpoints.UPDATE_MY_PERSONAL_DATA_ENDPOINT, data);
export const uploadProfileImageAPI = data => axios.put(endpoints.UPLOAD_PROFILE_IMAGE_ENDPOINT, data);

//Selects de datos personales
export const getRegions = (query) => axios.get(endpoints.GET_REGIONS_BY_COUNTRY_ENDPOINT + query);
export const getProvinces = (query) => axios.get(endpoints.GET_PROVINCES_BY_REGION_ENDPOINT + query);
export const getCounties = (query) => axios.get(endpoints.GET_COUNTIES_BY_PROVINCE_ENDPOINT + query);

//KPI
export const getKPIsAPI = () => axios.get(endpoints.GET_KPI_INDICATORS_ENDPOINT);
export const getKPIgridDataAPI = (query) => axios.get(endpoints.GET_KPI_GRID_DATA_ENDPOINT + query);
export const getKPIAdditionalDataAPI = (query) => axios.get(endpoints.GET_KPI_ADDITIONAL_DATA_ENDPOINT + query);

//Annotations
export const getMyAnnotationsAPI = () => axios.get(endpoints.MY_ANNOTATIONS_ENDPOINT);
export const getAnnotationsAPI = (query) => axios.get(endpoints.ANNOTATIONS_ENDPOINT + query);
export const createAnnotationAPI = (data) => axios.post(endpoints.ANNOTATIONS_ENDPOINT, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const updateAnnotationAPI = (data) => axios.put(endpoints.ANNOTATIONS_ENDPOINT, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const deleteAnnotationAPI = (data) => axios.delete(endpoints.ANNOTATIONS_ENDPOINT, { data });

//Annex
export const getMyAnnexesApi = () => axios.get(endpoints.ANNEXES_ENDPOINT);
export const getAnnexesApi = (query) => axios.get(endpoints.ANNEXES_ENDPOINT + query);
export const createAnnexApi = (data) => axios.post(endpoints.ANNEXES_ENDPOINT, data);
export const deleteAnnexApi = (data) => axios.delete(endpoints.ANNEXES_ENDPOINT, { data });
export const updateAnnexApi = (data) => axios.put(endpoints.ANNEXES_ENDPOINT, data);
export const downloadAnnexApi = (code) =>
  axios.post(endpoints.DOWNLOAD_ANNEX_ENDPOINT, code, {
    responseType: "blob",
  });
export const downloadAnnexFolderAPi = code =>
  axios.post(endpoints.DOWNLOAD_ANNEX_FOLDER_ENDPOINT, code, {
    responseType: "blob",
  });

export const exportExcelForIOS = (data) => axios.post(endpoints.EXPORT_EXCEL_FOR_IOS, data, {
  responseType: "blob",
});

//My Certificates
export const getMyCertificateListAPI = () => axios.get(endpoints.MY_CERTIFICATES_ENDPOINT);
export const confirmCertificateAPI = (data) => axios.post(endpoints.CONFIRM_MY_CERTIFICATE_ENDPOINT, data);
export const downloadMyCertificateAPI = (data) => axios.post(endpoints.DOWNLOAD_MY_CERTIFICATE_ENDPOINT, data, {
  responseType: "blob",
});

// Certificados
export const getCollaboratorsAndRegularCertificatesAPI = () => axios.get(endpoints.REGULAR_CERTIFICATES_AND_COLLABORATORS_ENDPOINT);
export const getCertificatesWithSignAPI = () => axios.get(endpoints.CERTIFICATES_WITH_SIGN_ENDPOINT);
export const downloadCertificatesAPI = (data) => axios.post(endpoints.CERTIFICATES_DOWNLOAD_ENDPOINT, data, {
  responseType: "blob",
});

export const getMyReceiptList = (query) => axios.get(endpoints.GET_MY_RECEIPT_LIST_ENDPOINT + query);
export const downloadMyReceiptApi = query => axios.get(endpoints.DOWNLOAD_RECEIPT_ENDPOINT + query, {
  responseType: "blob",
});
export const directDownloadMyDocumentWithDigitalSignApi = (data) => axios.post(endpoints.DIRECT_DOWNLOAD_DOCUMENT_DIGITAL_SIGN_ENDPOINT, data, { responseType: "blob" });
export const downloadMyDocumentWithDigitalSignApi = (data) => axios.post(endpoints.DOWNLOAD_DOCUMENT_DIGITAL_SIGN_ENDPOINT, data, { responseType: "blob" });
export const confirmReceiptApi = (data) => axios.post(endpoints.CONFIRM_RECEIPT_ENDPOINT, data);
export const getAvailableReceiptList = (query) => axios.get(endpoints.GET_AVAILABLE_RECEIPT_LIST_ENDPOINT + query);
export const getAvailableColaboratorsForReceipt = (query) => axios.get(endpoints.GET_AVAILABLE_COLABORATORS_FOR_RECEIPT_LIST_ENDPOINT + query);
export const downloadMultipleReceiptApi = (data) => axios.post(endpoints.DOWNLOAD_MULTIPLE_RECEIPTS_ENDPOINT, data, { responseType: "blob" });
export const getCollaboratorsWithDocumentsToSign = (query) => axios.get(endpoints.GET_COLLABORATORS_WITH_DOCUMENTS_TO_SIGN_ENDPOINT + query);
export const getCanSignApi = (data) => axios.post(endpoints.GET_CAN_SIGN_ENDPOINT, data);
export const signAndDownloadApi = (data) => axios.post(endpoints.SIGN_AND_DOWNLOAD_ENDPOINT, data);

// Loans
export const getMyCurrentAccountLoansResumeApi = () => axios.get(endpoints.GET_MY_CURRENT_ACCOUNT_LOANS_RESUME_ENDPOINT);
export const getMyCurrentAccountLoansByTypeAndCurrencyApi = (query) => axios.get(endpoints.GET_MY_CURRENT_ACCOUNT_LOANS_BY_TYPE_AND_CURRENCY_ENDPOINT + query);
export const getEmployeesCurrentAccountLoansResumeApi = (query) => axios.get(`${endpoints.GET_EMPLOYEES_CURRENT_ACCOUNT_LOANS_RESUME_ENDPOINT}${query || ''}`);
export const getEmployeesCurrentAccountLoansByTypeAndCurrencyApi = (query) => axios.get(endpoints.GET_EMPLOYEES_CURRENT_ACCOUNT_LOANS_BY_TYPE_AND_CURRENCY_ENDPOINT + query);

// Requests
export const getMyRequestsAPI = (query) => axios.get(endpoints.GET_MY_REQUESTS_ENDPOINT + query);
export const getEmployerPendingRequestsAPI = (query) => axios.get(endpoints.GET_EMPLOYER_PENDING_REQUESTS_ENDPOINT + query);
export const getManagerPendingRequestsAPI = (query) => axios.get(endpoints.GET_MANAGER_PENDING_REQUESTS_ENDPOINT + query);

// Delegate Attentions
export const getDelegationsAPI = () => axios.get(endpoints.DELEGATIONS_ENDPOINT);
export const setDelegationAPI = (data) => axios.post(endpoints.DELEGATIONS_ENDPOINT, data);
export const deleteDelegationAPI = (data) => axios.delete(endpoints.DELEGATIONS_ENDPOINT, { data });
export const getManagersEmployersBySocietyAPI = () => axios.get(endpoints.GET_MANAGERS_EMPLOYERS_BY_SOCIETY_ENDPOINT);

//SET REQUEST
export const setColaboratorAppealAPI = (data) => axios.post(endpoints.SET_COLABORATOR_APPEAL, data);
export const setColaboratorAppealPermissionAPI = (data) => axios.post(endpoints.SET_COLABORATOR_APPEAL_PERMISSION, data);
export const setColaboratorAppealAdvanceAPI = (data) => axios.post(endpoints.SET_COLABORATOR_APPEAL_ADVANCE, data);

//Modify Status
export const modifyVacationAppealStatusAPI = (data) => axios.post(endpoints.MODIFY_VACATION_APPEAL_STATUS, data);
export const modifyPermissionAppealStatusAPI = (data) => axios.post(endpoints.MODIFY_PERMISSION_APPEAL_STATUS, data);
export const modifyAdvanceAppealStatusAPI = (data) => axios.post(endpoints.MODIFY_ADVANCE_APPEAL_STATUS, data);

//Send Mail
export const sendRequestMailAPI = (data) => axios.post(endpoints.SEND_REQUEST_MAIL_ENDPOINT, data);
export const deleteRequestAPI = (data) => axios.post(endpoints.DELETE_REQUEST_ENDPOINT, data);
export const sendSignedDocumentsMailAPI = (data) => axios.post(endpoints.SEND_SIGNED_DOCUMENTS_MAIL_ENDPOINT, data);

//My Queries
export const getQueryGroupsAPI = () => axios.get(endpoints.QUERIES_GROUPS_ENDPOINT);
export const getQueriesAPI = (query) => axios.get(endpoints.QUERIES_ENDPOINT + query);
export const getQueryResultAPI = (data) => axios.post(endpoints.QUERIES_EXECUTE_ENDPOINT, data);
export const exportQueryAPI = (data) => axios.post(endpoints.QUERIES_EXPORT_ENDPOINT, data);
export const importQueryAPI = (data) => axios.post(endpoints.QUERIES_IMPORT_ENDPOINT, data);

export const getUserLocationDataAPI = (query) => axios.get(endpoints.GET_LOCATION_ENDPOINT + query);
export const getManagementDivisionsAPI = (query) => axios.get(endpoints.GET_MANAGEMENT_DIVISION_ENDPOINT + query);
export const getDepartmentsByManagementAPI = (query) => axios.get(endpoints.GET_DEPARTMENTS_BY_MANAGEMENT_ENDPOINT + query);
export const getSectionsByDepartmentAPI = (query) => axios.get(endpoints.GET_SECTIONS_BY_DEPARTMENT_ENDPOINT + query);

//Employee Master
export const updateEmployeeAPI = data => axios.put(endpoints.UPDATE_EMPLOYEE_ENDPOINT, data);
export const createEmployeeAPI = data => axios.post(endpoints.CREATE_EMPLOYEE_ENDPOINT, data);
export const getEmployeeReceiptsAPI = (query) => axios.get(endpoints.GET_RECEIPTS_EMPLOYEE + query);

//Notas
export const getNotesAPI = () => axios.get(endpoints.NOTES_ENDPOINT);
export const getNotesLabelsAPI = () => axios.get(endpoints.NOTES_LABELS_ENDPOINT);
export const createNotesLabelAPI = (data) => axios.post(endpoints.NOTES_LABEL_ENDPOINT, data);
export const updateNotesLabelAPI = (data) => axios.put(endpoints.NOTES_LABEL_ENDPOINT, data);
export const deleteNotesLabelAPI = (data) => axios.delete(endpoints.NOTES_LABEL_ENDPOINT, { data });
export const createNoteAPI = (data) => axios.post(endpoints.NOTE_ENDPOINT, data);
export const updateNoteAPI = (data) => axios.put(endpoints.NOTE_ENDPOINT, data);
export const deleteNoteAPI = (data) => axios.delete(endpoints.NOTE_ENDPOINT, { data });
export const shareNoteAPI = (data) => axios.post(endpoints.NOTE_SHARE_ENDPOINT, data);
export const sendNoteAPI = (data) => axios.post(endpoints.NOTE_SEND_ENDPOINT, data);
export const confirmNoteAPI = (data) => axios.post(endpoints.NOTE_CONFIRM_ENDPOINT, data);
export const uploadNoteAttachmentAPI = data => axios.post(endpoints.NOTE_ATTACH_ENDPOINT, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const deleteNoteAttachmentAPI = data => axios.delete(endpoints.NOTE_ATTACH_ENDPOINT, { data });
export const sendNoteTaskReminderAPI = (data) => axios.post(endpoints.NOTE_REMINDER_ENDPOINT, data);
export const getNoteCommentsAPI = (query) => axios.get(endpoints.NOTE_COMMENTS_ENDPOINT + query);
export const createNoteCommentAPI = (data) => axios.post(endpoints.NOTE_COMMENT_ENDPOINT, data);
export const updateNoteCommentAPI = (data) => axios.put(endpoints.NOTE_COMMENT_ENDPOINT, data);
export const deleteNoteCommentAPI = (data) => axios.delete(endpoints.NOTE_COMMENT_ENDPOINT, { data });


export const getSocietyPeopleAndGroupsEndpoint = () => axios.get(endpoints.GET_SOCIETY_PEOPLE_AND_GROUPS_ENDPOINT);

//TotemMagement
export const getAvailableTotems = () => axios.get(endpoints.TOTEMS_ENDPOINT);
export const addNewTotem = (data) => axios.post(endpoints.TOTEMS_ENDPOINT, data);
export const updateTotem = (data) => axios.put(endpoints.TOTEMS_ENDPOINT, data);
export const deleteTotem = (data) => axios.delete(endpoints.TOTEMS_ENDPOINT, { data });

// Vacaciones
export const getVacationsAPI = () => axios.get(endpoints.VACATIONS_ENDPOINT);
export const getEmployeeVacationsAPI = (query) => axios.get(endpoints.EMPLOYEE_VACATIONS_ENDPOINT + query);
export const createEmployeeVacationsAPI = (data) => axios.post(endpoints.EMPLOYEE_VACATIONS_ENDPOINT, data);
export const updateEmployeeVacationsAPI = (data) => axios.put(endpoints.EMPLOYEE_VACATIONS_ENDPOINT, data);
export const deleteEmployeeVacationsAPI = (data) => axios.delete(endpoints.EMPLOYEE_VACATIONS_ENDPOINT, { data });
export const downloadVacationsReceiptAPI = (data) => axios.post(endpoints.DOWNLOAD_VACATIONS_RECEIPT, data, { responseType: "blob" });
export const downloadVacationsSummaryAPI = (data) => axios.post(endpoints.DOWNLOAD_VACATION_SUMMARY_ENDPOINT, data, { responseType: "blob" });
export const sendVacationsMessageAPI = (data) => axios.post(endpoints.VACATIONS_MESSAGE_ENDPOINT, data);

//Firmantes
export const getSigners = () => axios.get(endpoints.SIGNERS_ENDPOINT);
export const addSigner = (data) => axios.post(endpoints.SIGNERS_ENDPOINT, data);
export const updateSigner = (data) => axios.put(endpoints.SIGNERS_ENDPOINT, data);
export const deleteSigner = (data) => axios.delete(endpoints.SIGNERS_ENDPOINT, { data });
export const getActiveTypeDocsToSign = () => axios.get(endpoints.GET_TYPE_DOCS_ENDPOINT);

export const getLicensePeriods = (query) => axios.get(endpoints.GET_LICENCE_PERIODS_ENDPOINT + query);

//Request Documents
export const getDocumentsToRequestApi = () => axios.get(endpoints.GET_DOCUMENTS_TO_REQUEST_ENDPOINT);
export const getRequestDocumentApi = (data) => axios.post(endpoints.REQUEST_DOCUMENT_ENDPOINT, data);

export const acceptSignedReceiptApi = (data) => axios.post(endpoints.ACCEPT_RECEIPT_WITH_DIGITAL_SIGN_ENDPOINT, data);
export const refuseSignedReceiptApi = (data) => axios.post(endpoints.REFUSE_RECEIPT_WITH_DIGITAL_SIGN_ENDPOINT, data);

// Documentos solicitados
export const getDocumentsRequests = () => axios.get(endpoints.GET_DOCUMENTS_REQUESTS_ENDPOINT);
export const signDocuments = (data) => axios.post(endpoints.SIGN_DOCUMENTS_ENDPOINT, data, { responseType: "blob" });
export const deleteDocumentsApi = (data) => axios.delete(endpoints.DELETE_DOCUMENTS_ENDPOINT, { data });
export const previewDocumentsAPI = (data) => axios.post(endpoints.PREVIEW_DOCUMENTS_ENDPOINT, data);

// Documentos firmados
export const getSignedDocuments = () => axios.get(endpoints.GET_SIGNED_DOCUMENTS_ENDPOINT);
export const downloadSignedDocuments = data => axios.post(endpoints.DOWNLOAD_SIGNED_DOCUMENTS_ENDPOINT, data, { responseType: "blob" });
export const deleteSignedDocuments = data => axios.post(endpoints.DELETE_SIGNED_DOCUMENTS_ENDPOINT, data);

// Requests Level 2
export const getMyRequestsManagerAPI = (query) => axios.get(endpoints.GET_MY_REQUESTS_MANAGER_ENDPOINT + query);
export const getMyPendingEmployerAPI = (query) => axios.get(endpoints.GET_MY_PENDING_EMPLOYER_ENDPOINT + query);
export const derivateManagerAppealAPI = (data) => axios.post(endpoints.DERIVATE_MANAGER_APPEAL_ENDPOINT, data);
export const modifyRaiseAppealStatusAPI = (data) => axios.post(endpoints.MODIFY_RAISE_APPEAL_STATUS, data);
export const modifyFireAppealStatus = (data) => axios.post(endpoints.MODIFY_FIRE_APPEAL_STATUS, data);

export const setAppealFire = data => axios.post(endpoints.SET_APPEAL_FIRE_ENDPOINT, data);
export const setAppealRaise = data => axios.post(endpoints.SET_APPEAL_RAISE_ENDPOINT, data);

export const registerAsistanceAPI = data => axios.post(endpoints.REGISTER_ASSISTANCE_ENDPOINT, data);

//Fiscalizacion
export const getCountries = () => axios.get(endpoints.GET_COUNTRIES);
export const loginInspector = (data) => axios.post(endpoints.LOGIN_INSPECTOR, data);
export const getCountrySocieties = () => axios.get(endpoints.GET_COUNTRIES_SOCIETIES);
export const getSocietyDocs = (query) => axios.get(endpoints.GET_SOCIETY_DOCS + query);
export const previewDocumentAPI = (data) => axios.post(endpoints.PREVIEW_DOCUMENT, data);
export const changePassword = (data) => axios.post(endpoints.CHANGE_PASSWORD, data);
export const lostPassword = (data) => axios.post(endpoints.LOST_PASSWORD, data);

//Grupos
export const getGroupsDataAPI = () => axios.get(endpoints.GROUPS_ENDPOINT);
export const createGroupAPI = (data) => axios.post(endpoints.GROUPS_ENDPOINT, data);
export const editGroupAPI = (data) => axios.put(endpoints.GROUPS_ENDPOINT, data);
export const deleteGroupAPI = (data) => axios.delete(endpoints.GROUPS_ENDPOINT, { data });
export const leaveGroupAPI = (data) => axios.post(endpoints.LEAVE_GROUP_ENDPOINT, data);


//ABM 3 Carpeta Digital
export const getGenericFilesForDigitalFolderEndpoint = () => axios.get(endpoints.GET_DIGITAL_FOLDER_GENERIC_FILES);
export const getColaboratorFilesForDigitalFolderEndpoint = () => axios.get(endpoints.GET_DIGITAL_FOLDER_COLABORATOR_FILES);
export const updateColaboratorFilesForDigitalFolderEndpoint = (data) => axios.put(endpoints.GET_DIGITAL_FOLDER_COLABORATOR_FILES, data);
export const getFilesByPositionForDigitalFolderEndpoint = () => axios.get(endpoints.GET_DIGITAL_FOLDER_FILES_BY_POSITION);
export const updateGenericFilesForDigitalFolderEndpoint = (data) => axios.post(endpoints.UPDATE_DIGITAL_FOLDER_GENERIC_FILES, data);
export const deleteGenericFilesForDigitalFolderEndpoint = (data) => axios.post(endpoints.DELETE_FOR_POSITION_FILES, data);
export const addFileForPositionForDigitalFolderEndpoint = (data) => axios.post(endpoints.ADD_FOR_POSITION_FILES, data);
export const updateFileForPositionForDigitalFolderEndpoint = (data) => axios.post(endpoints.UPDATE_FOR_POSITION_FILES, data);

//GALLERY
export const getImagesFromGallery = (query) => axios.get(endpoints.GET_IMAGES_FROM_GALLERY + query);
export const uploadImageToGalleryEndpoint = data => axios.post(endpoints.UPLOAD_IMAGE_TO_GALLERY, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const deleteImageFromGalleryEndpoint = (data) => axios.post(endpoints.DELETE_IMAGE_FROM_GALLERY, data);

//External Documents
export const getExternalDocumentsEndpoint = () => axios.get(endpoints.GET_EXTERNAL_DOCUMENTS);
export const getExternalDocumentsRolesEndpoint = () => axios.get(endpoints.GET_EXTERNAL_DOCUMENTS_ROLES);
export const uploadExternalDocumentsEndpoint = (data) => axios.post(endpoints.UPLOAD_EXTERNAL_DOCUMENTS, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const downloadExternalPreviewEndpoint = (data) => axios.post(endpoints.DOWNLOAD_EXTERNAL_DOCUMENTS_PREVIEW, data);
export const deleteExternalDocumentsEndpoint = (data) => axios.post(endpoints.DELETE_EXTERNAL_DOCUMENTS, data);
export const signExternalDocumentsEndpoint = (data) => axios.post(endpoints.SIGN_EXTERNAL_DOCUMENTS, data);

//Documentos Digitales
export const getDigitalDocumentsToApprove = () => axios.get(endpoints.GET_DIGITAL_DOCUMENTS_TO_APPROVE);
export const confirmDigitalDocumentApi = (data) => axios.post(endpoints.CONFIRM_DIGITAL_DOCUMENT_ENDPOINT, data);

// General Settings
export const getGeneralSettingsAPI = () => axios.get(endpoints.GENERAL_SETTINGS_ENDPOINT);
export const updateGeneralSettingsAPI = (data) => axios.put(endpoints.GENERAL_SETTINGS_ENDPOINT, data);

//Poll Settings
export const getPollSettingsAPI = () => axios.get(endpoints.POLLS_SETTINGS_ENDPOINT);
export const deletePollSettinsAPI = (data) => axios.delete(endpoints.POLLS_SETTINGS_ENDPOINT, { data });
export const createPollSettinsAPI = (data) => axios.post(endpoints.POLLS_SETTINGS_ENDPOINT, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const modifyPollSettinsAPI = (data) => axios.put(endpoints.POLLS_SETTINGS_ENDPOINT, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});

//Access settings
export const getAccessSettings = () => axios.get(endpoints.ACCESS_SETTINGS);
export const createAccessSettings = (data) => axios.post(endpoints.ACCESS_SETTINGS, data);
export const updateAccessSettings = (data) => axios.put(endpoints.ACCESS_SETTINGS, data);
export const deleteAccessSettings = (data) => axios.delete(endpoints.ACCESS_SETTINGS, { data });

// Send mail
export const sendMessageAPI = (data) => axios.post(endpoints.SEND_MESSAGE_ENDPOINT, data);

//Annotations Analysis
export const getAnnotationAnalysisAPI = () => axios.get(endpoints.GET_ANNOTATIONS_ANALYSIS);

//Calculation Processes
export const getCalculationProcessesAPI = (query) => axios.get(endpoints.GET_CALCULATION_PROCESSES + query);
export const getCalcProcessTraceabilityAPI = (query) => axios.get(endpoints.GET_CALCULATION_PROCESSES_TRACEABILITY + query);
export const getProcessesEmployeesAPI = (query) => axios.get(endpoints.GET_PROCESSES_EMPLOYEES + query);
export const createProcessAPI = (data) => axios.post(endpoints.CREATE_MODIFY_CALCULATION_PROCESSES, data);
export const modifyProcessAPI = (data) => axios.put(endpoints.CREATE_MODIFY_CALCULATION_PROCESSES, data);
export const deleteProcessAPI = (data) => axios.delete(endpoints.CREATE_MODIFY_CALCULATION_PROCESSES, { data });
export const execute_R8 = (query) => axios.get(endpoints.EXECUTE_R8 + query);
export const generateThirdPartyFileAPI = (data) => axios.post(endpoints.GENERATE_THIRD_PARTY_FILE, data);
export const approveProcessAPI = (data) => axios.post(endpoints.APPROVE_PROCESS_ENDPOINT, data);

//Digital Folder Analysis
export const getDigitalFolderAnalysisAPI = (query) => axios.get(endpoints.GET_DIGITAL_FOLDER_ANALYSIS + query);
export const getDigitalFolderInstancesAPI = () => axios.get(endpoints.GET_DIGITAL_FOLDER_INSTANCES);

//Employees processes
export const getCalculationProcessColaboratorsAPI = (query) => axios.get(endpoints.GET_CALCULATION_PROCESS_COLABORATORS + query);
export const deleteCalculationProcessColaboratorAPI = (data) => axios.delete(endpoints.DELETE_PROCESS_COLABORATORS, { data });
export const getColaboratorCalculationProcessResultsAPI = (query) => axios.get(endpoints.GET_EXTRA_CALCULATION_PROCESS_DATA + query);
export const publishProcessAPI = (data) => axios.post(endpoints.PUBLISH_PROCESS_ENDPOINT, data);
export const getProcessDashboardFromServerAPI = (query) => axios.get(endpoints.GET_PROCESS_DASHBOARD_FROM_SERVER + query, {
  headers: {
    'Cache-Control': 'max-age=3, must-revalidate',
  }
});
export const getAnalyticsConceptsPerStructureFromServerAPI = (query) => axios.get(endpoints.GET_ANALYTICS_DASHBOARD_FROM_SERVER + query);

//geoanalysis
export const getGeoanalysisGroupsAPI = () => axios.get(endpoints.GET_GEOANALYSIS_GROUPS);
export const getGeoanalysisQueriesAPI = (query) => axios.get(endpoints.GET_GEOANALYSIS_QUERIES + query);
export const executeGeoanalysisQueryAPI = (data) => axios.post(endpoints.EXECUTE_GEOANALYSIS_QUERY, data);
export const exportGeoanalysisQueryAPI = (data) => axios.post(endpoints.EXPORT_GEOANALYSIS_QUERY, data);
export const importGeoanalysisQueryAPI = (data) => axios.post(endpoints.IMPORT_GEOANALYSIS_QUERY, data);

//Publication Settings
export const getPublicationSettingsAPI = () => axios.get(endpoints.PUBLICATION_SETTINGS);
export const deletePublicationSettingsAPI = (data) => axios.delete(endpoints.PUBLICATION_SETTINGS, { data });
export const createPublicationSettingsAPI = (data) => axios.post(endpoints.PUBLICATION_SETTINGS, data);
export const modifyPublicationSettingsAPI = (data) => axios.put(endpoints.PUBLICATION_SETTINGS, data);
export const verifyURLHasImageAPI = (data) => axios.post(endpoints.VERIFY_URL_HAS_IMAGE, data);
export const orderPublicationSettingsAPI = (data) => axios.post(endpoints.ORDER_PUBLICATION_SETTINGS, data);

//Filters
export const getFiltersAPI = () => axios.get(endpoints.GET_FILTERS);
export const applyFiltersAPI = (data) => axios.post(endpoints.APPLY_FILTERS, data);

// Organigrama
export const getOrgChart = (query) => axios.get(endpoints.GET_ORG_CHART_WITH_BRANCH + query);
export const getPeopleOrgChart = () => axios.get(endpoints.GET_PEOPLE_ORGCHART);
export const recomposePositionsAPI = (data) => axios.post(endpoints.RECOMPOSE_POSITIONS_ENDPOINT, data);
export const getClientBranches = () => axios.get(endpoints.GET_CLIENT_BRANCHES);
export const saveOrgChart = (data) => axios.post(endpoints.SAVE_ORG_CHART, data);

//Centers settings
export const getCentersSettings = () => axios.get(endpoints.GET_CENTERS_SETTINGS);
export const createCentersSettings = (data) => axios.post(endpoints.CREATE_CENTERS_SETTINGS, data);
export const updateCentersSettings = (data) => axios.post(endpoints.UPDATE_CENTERS_SETTINGS, data);
export const deleteCentersSettings = (data) => axios.post(endpoints.DELETE_CENTERS_SETTINGS, data);

//Permissions Settings
export const getPermissionsSettingsAPI = () => axios.get(endpoints.PERMISSIONS_SETTINGS);
export const createPermissionSettingsAPI = (data) => axios.post(endpoints.PERMISSIONS_SETTINGS, data);
export const modifyPermissionSettingsAPI = (data) => axios.put(endpoints.PERMISSIONS_SETTINGS, data);
export const deletePermissionSettingsAPI = (data) => axios.delete(endpoints.PERMISSIONS_SETTINGS, { data });

//Family settings
export const getFamilySettings = () => axios.get(endpoints.FAMILY_SETTINGS);
export const createFamilySettings = (data) => axios.post(endpoints.FAMILY_SETTINGS, data);
export const updateFamilySettings = (data) => axios.put(endpoints.FAMILY_SETTINGS, data);
export const deleteFamilySettings = (data) => axios.delete(endpoints.FAMILY_SETTINGS, { data });

//Function settings
export const getFunctionsSettings = () => axios.get(endpoints.FUNCTIONS_SETTINGS);
export const createFunctionsSettings = (data) => axios.post(endpoints.FUNCTIONS_SETTINGS, data);
export const updateFunctionsSettings = (data) => axios.put(endpoints.FUNCTIONS_SETTINGS, data);
export const deleteFunctionsSettings = (data) => axios.delete(endpoints.FUNCTIONS_SETTINGS, { data });

//Advances Settings
export const getAdvancesSettingsAPI = () => axios.get(endpoints.ADVANCES_SETTINGS);
export const deleteAdvanceSettingsAPI = (data) => axios.delete(endpoints.ADVANCES_SETTINGS, { data });
export const createAdvanceSettingsAPI = (data) => axios.post(endpoints.ADVANCES_SETTINGS, data);
export const modifyAdvanceSettingsAPI = (data) => axios.put(endpoints.ADVANCES_SETTINGS, data);

//Document publication
export const getDocumentPublicationAPI = () => axios.get(endpoints.DOCUMENT_PUBLICATION);
export const createDocumentPublicationAPI = (data) => axios.post(endpoints.DOCUMENT_PUBLICATION, data);
export const modifyDocumentPublicationAPI = (data) => axios.put(endpoints.DOCUMENT_PUBLICATION, data);
export const deleteDocumentPublicationAPI = (data) => axios.delete(endpoints.DOCUMENT_PUBLICATION, { data });

//Contract types Settings
export const getContractTypesSettingsAPI = () => axios.get(endpoints.CONTRACT_TYPES_SETTINGS);
export const createContractTypesSettingsAPI = (data) => axios.post(endpoints.CONTRACT_TYPES_SETTINGS, data);
export const modifyContractTypesSettingsAPI = (data) => axios.put(endpoints.CONTRACT_TYPES_SETTINGS, data);
export const deleteContractTypesSettingsAPI = (data) => axios.delete(endpoints.CONTRACT_TYPES_SETTINGS, { data });

//Assistance voice
export const getAssistantVoiceTypeAPI = () => axios.get(endpoints.GET_ASSISTANCE_VOICE_TYPE);

//Concept groups
export const getConceptGroupsAPI = () => axios.get(endpoints.CONCEPT_GROUPS);
export const createConceptGroupsAPI = (data) => axios.post(endpoints.CONCEPT_GROUPS, data);
export const updateConceptGroupsAPI = (data) => axios.put(endpoints.CONCEPT_GROUPS, data);
export const deleteConceptGroupsAPI = (data) => axios.delete(endpoints.CONCEPT_GROUPS, { data });

//Contractor Settings
export const getContractorSettingsAPI = () => axios.get(endpoints.CONTRACTOR_SETTINGS);
export const createContractorSettingsAPI = (data) => axios.post(endpoints.CONTRACTOR_SETTINGS, data);
export const modifyContractorSettingsAPI = (data) => axios.put(endpoints.CONTRACTOR_SETTINGS, data);
export const deleteContractorSettingsAPI = (data) => axios.delete(endpoints.CONTRACTOR_SETTINGS, { data });

//Functionary types Settings
export const getFunctionaryTypesSettingsAPI = () => axios.get(endpoints.FUNCTIONARY_TYPES_SETTINGS);
export const createFunctionaryTypesSettingsAPI = (data) => axios.post(endpoints.FUNCTIONARY_TYPES_SETTINGS, data);
export const modifyFunctionaryTypesSettingsAPI = (data) => axios.put(endpoints.FUNCTIONARY_TYPES_SETTINGS, data);
export const deleteFunctionaryTypesSettingsAPI = (data) => axios.delete(endpoints.FUNCTIONARY_TYPES_SETTINGS, { data });

//Location settings
export const getLocationSettingsAPI = () => axios.get(endpoints.LOCATION_SETTINGS_ENDPOINT);
export const createLocationSettingsAPI = (data) => axios.post(endpoints.LOCATION_SETTINGS_ENDPOINT, data);
export const updateLocationSettingsAPI = (data) => axios.put(endpoints.LOCATION_SETTINGS_ENDPOINT, data);
export const deleteLocationSettingsAPI = (data) => axios.delete(endpoints.LOCATION_SETTINGS_ENDPOINT, { data });

//Works and tasks settings
export const getWorksAndTasksSettingsAPI = () => axios.get(endpoints.WORKS_AND_TASKS_SETTINGS_ENDPOINT);
export const createWorksAndTasksSettingsAPI = (data) => axios.post(endpoints.WORKS_AND_TASKS_SETTINGS_ENDPOINT, data);
export const updateWorksAndTasksSettingsAPI = (data) => axios.put(endpoints.WORKS_AND_TASKS_SETTINGS_ENDPOINT, data);
export const deleteWorksAndTasksSettingsAPI = (data) => axios.delete(endpoints.WORKS_AND_TASKS_SETTINGS_ENDPOINT, { data });


//Divisions settings
export const getDivisionsAPI = () => axios.get(endpoints.DIVISIONS_SETTINGS_ENDPOINT);
export const createDivisionsAPI = (data) => axios.post(endpoints.DIVISIONS_SETTINGS_ENDPOINT, data);
export const updateDivisionsAPI = (data) => axios.put(endpoints.DIVISIONS_SETTINGS_ENDPOINT, data);
export const deleteDivisionsAPI = (data) => axios.delete(endpoints.DIVISIONS_SETTINGS_ENDPOINT, { data });

//Concepts and formulas
export const getConceptAndFormulasAPI = () => axios.get(endpoints.CONCEPTS_AND_FORMULAS);
export const createConceptAndFormulasAPI = (data) => axios.post(endpoints.CONCEPTS_AND_FORMULAS, data);
export const modifyConceptAndFormulasAPI = (data) => axios.put(endpoints.CONCEPTS_AND_FORMULAS, data);
export const deleteConceptAndFormulasAPI = (data) => axios.delete(endpoints.CONCEPTS_AND_FORMULAS, { data });
export const duplicateConceptAndFormulasAPI = (data) => axios.post(endpoints.CONCEPTS_AND_FORMULAS_DUPLICATE, data);
export const getConceptDetailsAPI = (query) => axios.get(endpoints.CONCEPTS_AND_FORMULAS_DETAILS + query);
export const searchConceptsAPI = (query) => axios.get(endpoints.CONCEPTS_AND_FORMULAS_SEARCH + query);
export const uploadConceptsFormulasFormFileAPI = (data) => axios.post(endpoints.CONCEPTS_AND_FORMULAS_FILE, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});

//Concepts and formulas II (Funciones)
export const getFunctionsAPI = () => axios.get(endpoints.FUNCTIONS);
export const createFunctionAPI = (data) => axios.post(endpoints.FUNCTIONS, data);
export const modifyFunctionAPI = (data) => axios.put(endpoints.FUNCTIONS, data);
export const deleteFunctionAPI = (data) => axios.delete(endpoints.FUNCTIONS, { data });
export const searchFunctionsAPI = (query) => axios.get(endpoints.FUNCTIONS_SEARCH + query);
export const duplicateFunctionAPI = (data) => axios.post(endpoints.FUNCTIONS_DUPLICATE, data);

//Accumulator settings
export const getAccumulatorsSettingsAPI = () => axios.get(endpoints.ACCUMULATORS_SETTINGS_ENDPOINT);
export const createAccumulatorsSettingsAPI = (data) => axios.post(endpoints.ACCUMULATORS_SETTINGS_ENDPOINT, data);
export const updateAccumulatorsSettingsAPI = (data) => axios.put(endpoints.ACCUMULATORS_SETTINGS_ENDPOINT, data);
export const deleteAccumulatorsSettingsAPI = (data) => axios.delete(endpoints.ACCUMULATORS_SETTINGS_ENDPOINT, { data });

//Categorias y especialidades
export const getCategoriesAndSpecialtiesAPI = (query) => axios.get(endpoints.CATEGORIES_AND_SPECIALTIES_ENDPOINT + query);
export const createCategoriesAndSpecialtiesAPI = (data) => axios.post(endpoints.CATEGORIES_AND_SPECIALTIES_ENDPOINT, data);
export const updateCategoriesAndSpecialtiesAPI = (data) => axios.put(endpoints.CATEGORIES_AND_SPECIALTIES_ENDPOINT, data);
export const deleteCategoriesAndSpecialtiessAPI = (data) => axios.delete(endpoints.CATEGORIES_AND_SPECIALTIES_ENDPOINT, { data });

//payroll types
export const getPayrollTypesSettingsAPI = () => axios.get(endpoints.PAYROLL_TYPES_SETTINGS_ENDPOINT);
export const createPayrollTypesSettingsAPI = (data) => axios.post(endpoints.PAYROLL_TYPES_SETTINGS_ENDPOINT, data);
export const updatePayrollTypesSettingsAPI = (data) => axios.put(endpoints.PAYROLL_TYPES_SETTINGS_ENDPOINT, data);
export const deletePayrollTypesSettingsAPI = (data) => axios.delete(endpoints.PAYROLL_TYPES_SETTINGS_ENDPOINT, { data });
export const duplicatePayrollTypeAPI = (data) => axios.post(endpoints.DUPLICATE_PAYROLL_TYPES_SETTINGS_ENDPOINT, data);

//allergies exams diseases
export const getAllergiesExamsDiseasesAPI = (query) => axios.get(endpoints.ALLERGIES_EXAMS_DISEASES_ENDPOINT + query);
export const createAllergiesExamsDiseasesAPI = (data) => axios.post(endpoints.ALLERGIES_EXAMS_DISEASES_ENDPOINT, data);
export const updateAllergiesExamsDiseasesAPI = (data) => axios.put(endpoints.ALLERGIES_EXAMS_DISEASES_ENDPOINT, data);
export const deleteAllergiesExamsDiseasesAPI = (data) => axios.delete(endpoints.ALLERGIES_EXAMS_DISEASES_ENDPOINT, { data });

//Calculation references
export const getCalculationReferencesAPI = () => axios.get(endpoints.CALCULATION_REFERENCES_ENDPOINT);
export const createCalculationReferencesAPI = (data) => axios.post(endpoints.CALCULATION_REFERENCES_ENDPOINT, data);
export const updateCalculationReferencesAPI = (data) => axios.put(endpoints.CALCULATION_REFERENCES_ENDPOINT, data);
export const deleteCalculationReferencesAPI = (data) => axios.delete(endpoints.CALCULATION_REFERENCES_ENDPOINT, { data });

//entry exit types
export const getEntryExitTypesSettingsAPI = (query) => axios.get(endpoints.ENTRY_EXIT_TYPES_SETTINGS_ENDPOINT + query);
export const createEntryExitTypesSettingsAPI = (data) => axios.post(endpoints.ENTRY_EXIT_TYPES_SETTINGS_ENDPOINT, data);
export const updateEntryExitTypesSettingsAPI = (data) => axios.put(endpoints.ENTRY_EXIT_TYPES_SETTINGS_ENDPOINT, data);
export const deleteEntryExitTypesSettingsAPI = (data) => axios.delete(endpoints.ENTRY_EXIT_TYPES_SETTINGS_ENDPOINT, { data });

// Organizacion estructural
export const getOrgStructure = () => axios.get(endpoints.ORGANIZATION_STRUCTURE_ENDPOINT);
export const saveOrgStructure = (data) => axios.post(endpoints.SAVE_ORGANIZATION_STRUCTURE_ENDPOINT, data);
export const getDepartmentOccupationEndpoint = (query) => axios.get(endpoints.ORGANIZATION_OCCUPATION_DATA + query);
export const getPositionOccupationEndpoint = (query) => axios.get(endpoints.POSITION_OCUPATION_DATA + query);

// Organizacion estructural
export const getGeoStructure = () => axios.get(endpoints.GEOGRAPHICAL_STRUCTURE_ENDPOINT);
export const saveGeoStructure = (data) => axios.post(endpoints.GEOGRAPHICAL_STRUCTURE_ENDPOINT, data);
export const getGeographicalOccupationEndpoint = (query) => axios.get(endpoints.GEOGRAPHICAL_OCCUPATION_DATA + query);

//issue Types Settings
export const getAbsenceTypesSettingsAPI = () => axios.get(endpoints.ABSENCE_SETTINGS_ENDPOINT);
export const createAbsenceTypesSettingsAPI = (data) => axios.post(endpoints.ABSENCE_SETTINGS_ENDPOINT, data);
export const updateAbsenceTypesSettingsAPI = (data) => axios.put(endpoints.ABSENCE_SETTINGS_ENDPOINT, data);
export const deleteAbsenceTypesSettingsAPI = (data) => axios.delete(endpoints.ABSENCE_SETTINGS_ENDPOINT, { data });

export const getPermissionTypesSettingsAPI = () => axios.get(endpoints.PERMISSION_TYPES_SETTINGS_ENDPOINT);
export const createPermissionTypesSettingsAPI = (data) => axios.post(endpoints.PERMISSION_TYPES_SETTINGS_ENDPOINT, data);
export const updatePermissionTypesSettingsAPI = (data) => axios.put(endpoints.PERMISSION_TYPES_SETTINGS_ENDPOINT, data);
export const deletePermissionTypesSettingsAPI = (data) => axios.delete(endpoints.PERMISSION_TYPES_SETTINGS_ENDPOINT, { data });

export const getLicenceTypesSettingsAPI = () => axios.get(endpoints.LICENCE_TYPES_SETTINGS_ENDPOINT);
export const createLicenceTypesSettingsAPI = (data) => axios.post(endpoints.LICENCE_TYPES_SETTINGS_ENDPOINT, data);
export const updateLicenceTypesSettingsAPI = (data) => axios.put(endpoints.LICENCE_TYPES_SETTINGS_ENDPOINT, data);
export const deleteLicenceTypesSettingsAPI = (data) => axios.delete(endpoints.LICENCE_TYPES_SETTINGS_ENDPOINT, { data });

//Positions Classification Settings
export const getPositionsClassificationSettingsAPI = () => axios.get(endpoints.POSITIONS_CLASSIFICATION_SETTINGS);
export const deletePositionsClassificationSettingsAPI = (data) => axios.delete(endpoints.POSITIONS_CLASSIFICATION_SETTINGS, { data });
export const createPositionsClassificationSettingsAPI = (data) => axios.post(endpoints.POSITIONS_CLASSIFICATION_SETTINGS, data);
export const modifyPositionsClassificationSettingsAPI = (data) => axios.put(endpoints.POSITIONS_CLASSIFICATION_SETTINGS, data);

export const getEmployeeEditableDataAPI = (query) => axios.get(endpoints.EMPLOYEE_EDITABLE_DATA + query);

//Query definition
export const getQueryDefinitionAPI = () => axios.get(endpoints.QUERY_DEFINITION_ENDPOINT);
export const createQueryDefinitionAPI = (data) => axios.post(endpoints.QUERY_DEFINITION_ENDPOINT, data);
export const modifyQueryDefinitionAPI = (data) => axios.put(endpoints.QUERY_DEFINITION_ENDPOINT, data);
export const deleteQueryDefinitionAPI = (data) => axios.delete(endpoints.QUERY_DEFINITION_ENDPOINT, { data });
export const duplicateQueryDefinitionAPI = (data) => axios.post(endpoints.DUPLICATE_QUERY_DEFINITION_ENDPOINT, data);

//Query definition parameters
export const getQueryParametersAPI = (query) => axios.get(endpoints.QUERY_PARAMETERS_ENDPOINT + query);
export const createQueryParameterAPI = (data) => axios.post(endpoints.QUERY_PARAMETERS_ENDPOINT, data);
export const modifyQueryParameterAPI = (data) => axios.put(endpoints.QUERY_PARAMETERS_ENDPOINT, data);
export const deleteQueryParameterAPI = (data) => axios.delete(endpoints.QUERY_PARAMETERS_ENDPOINT, { data });
export const duplicateQueryParameterAPI = (data) => axios.post(endpoints.DUPLICATE_QUERY_PARAMETERS_ENDPOINT, data);

//Geo query
export const getGeoQueryDefinitionAPI = () => axios.get(endpoints.GEO_QUERY_DEFINITION_ENDPOINT);
export const createGeoQueryDefinitionAPI = (data) => axios.post(endpoints.GEO_QUERY_DEFINITION_ENDPOINT, data);
export const modifyGeoQueryDefinitionAPI = (data) => axios.put(endpoints.GEO_QUERY_DEFINITION_ENDPOINT, data);
export const deleteGeoQueryDefinitionAPI = (data) => axios.delete(endpoints.GEO_QUERY_DEFINITION_ENDPOINT, { data });
export const duplicateGeoQueryDefinitionAPI = (data) => axios.post(endpoints.DUPLICATE_GEO_QUERY_DEFINITION_ENDPOINT, data);

//Geo Query parameters
export const getGeoQueryParametersAPI = (query) => axios.get(endpoints.GEO_QUERY_PARAMETERS_ENDPOINT + query);
export const createGeoQueryParameterAPI = (data) => axios.post(endpoints.GEO_QUERY_PARAMETERS_ENDPOINT, data);
export const modifyGeoQueryParameterAPI = (data) => axios.put(endpoints.GEO_QUERY_PARAMETERS_ENDPOINT, data);
export const deleteGeoQueryParameterAPI = (data) => axios.delete(endpoints.GEO_QUERY_PARAMETERS_ENDPOINT, { data });
export const duplicateGeoQueryParameterAPI = (data) => axios.post(endpoints.DUPLICATE_GEO_QUERY_PARAMETERS_ENDPOINT, data);

//General values
export const getGeneralValuesAPI = () => axios.get(endpoints.GENERAL_VALUES_ENDPOINT);
export const deleteGeneralValuesAPI = (data) => axios.delete(endpoints.GENERAL_VALUES_ENDPOINT, { data });
export const setGeneralValuesAPI = (data) => axios.post(endpoints.GENERAL_VALUES_ENDPOINT, data);

//ABM KPI
export const getKPIdefinitionAPI = () => axios.get(endpoints.KPI_DEFINITION_ENDPOINT);
export const createKPIdefinitionAPI = (data) => axios.post(endpoints.KPI_DEFINITION_ENDPOINT, data);
export const modifyKPIdefinitionAPI = (data) => axios.put(endpoints.KPI_DEFINITION_ENDPOINT, data);
export const deleteKPIdefinitionAPI = (data) => axios.delete(endpoints.KPI_DEFINITION_ENDPOINT, { data });
export const duplicateKPIdefinitionAPI = (data) => axios.post(endpoints.KPI_DEFINITION_DUPLICATE_ENDPOINT, data);

//Alerts settings
export const getAlertsSettingsAPI = (query) => axios.get(endpoints.ALERTS_SETTINGS_ENDPOINT + query);
export const createAlertsSettingsAPI = (data) => axios.post(endpoints.ALERTS_SETTINGS_ENDPOINT, data);
export const modifyAlertsSettingsAPI = (data) => axios.put(endpoints.ALERTS_SETTINGS_ENDPOINT, data);
export const deleteAlertsSettingsAPI = (data) => axios.delete(endpoints.ALERTS_SETTINGS_ENDPOINT, { data });
export const duplicateAlertsSettingsAPI = (data) => axios.post(endpoints.ALERTS_SETTINGS_DUPLICATE_ENDPOINT, data);
export const getAlertActivacionSettingsAPI = (query) => axios.get(endpoints.ALERTS_SETTINGS_ACTIVATION_ENDPOINT + query);
export const modifyAlertActivacionSettingsAPI = (data) => axios.put(endpoints.ALERTS_SETTINGS_ACTIVATION_ENDPOINT, data);

//Conjunto de conceptos
export const getConceptsSetAPI = () => axios.get(endpoints.CONCEPTS_SET_ENDPOINT);
export const createConceptsSetAPI = (data) => axios.post(endpoints.CONCEPTS_SET_ENDPOINT, data);
export const updateConceptsSetAPI = (data) => axios.put(endpoints.CONCEPTS_SET_ENDPOINT, data);
export const deleteConceptsSetAPI = (data) => axios.delete(endpoints.CONCEPTS_SET_ENDPOINT, { data });
export const duplicateConceptsSetAPI = (data) => axios.post(endpoints.DUPLICATE_CONCEPTS_SET_ENDPOINT, data);
export const getConceptsSetDetailsAPI = () => axios.get(endpoints.CONCEPTS_SET_DETAILS_ENDPOINT);
export const createConceptsSetDetailAPI = (data) => axios.post(endpoints.CONCEPTS_SET_DETAILS_ENDPOINT, data);
export const updateConceptsSetDetailAPI = (data) => axios.put(endpoints.CONCEPTS_SET_DETAILS_ENDPOINT, data);
export const deleteConceptsSetDetailAPI = (data) => axios.delete(endpoints.CONCEPTS_SET_DETAILS_ENDPOINT, { data });
export const getConceptsSetConceptsAPI = () => axios.get(endpoints.CONCEPTS_SET_CONCEPTS_ENDPOINT);

// ABM Concept Structure
export const getConceptStructureAPI = () => axios.get(endpoints.CONCEPT_STRUCTURE_ENDPOINT);
export const deleteConceptStructureAPI = (data) => axios.delete(endpoints.CONCEPT_STRUCTURE_ENDPOINT, { data });
export const setConceptStructureAPI = (data) => axios.post(endpoints.CONCEPT_STRUCTURE_ENDPOINT, data);

//Third party Classification
export const getThirdPartyClassificationAPI = () => axios.get(endpoints.THIRD_PARTY_CLASSIFICATION);
export const createPositionsClassificationAPI = (data) => axios.post(endpoints.THIRD_PARTY_CLASSIFICATION, data);
export const updateThirdPartyClassificationAPI = (data) => axios.put(endpoints.THIRD_PARTY_CLASSIFICATION, data);
export const deleteThirdPartyClassificationAPI = (data) => axios.delete(endpoints.THIRD_PARTY_CLASSIFICATION, { data });

//Third party
export const getThirdPartyAPI = () => axios.get(endpoints.THIRD_PARTY);
export const createPositionsAPI = (data) => axios.post(endpoints.THIRD_PARTY, data);
export const updateThirdPartyAPI = (data) => axios.put(endpoints.THIRD_PARTY, data);
export const deleteThirdPartyAPI = (data) => axios.delete(endpoints.THIRD_PARTY, { data });

//Labor unions
export const getLaborUnionsAPI = () => axios.get(endpoints.LABOR_UNION);
export const deleteLaborUnionAPI = (data) => axios.delete(endpoints.LABOR_UNION, { data });
export const createLaborUnionAPI = (data) => axios.post(endpoints.LABOR_UNION, data);
export const modifyLaborUnionAPI = (data) => axios.put(endpoints.LABOR_UNION, data);

//Payment types
export const getPaymentTypesAPI = () => axios.get(endpoints.PAYMENT_TYPES);
export const deletePaymentTypeAPI = (data) => axios.delete(endpoints.PAYMENT_TYPES, { data });
export const createPaymentTypeAPI = (data) => axios.post(endpoints.PAYMENT_TYPES, data);
export const modifyPaymentTypeAPI = (data) => axios.put(endpoints.PAYMENT_TYPES, data);

//Document templates
export const getDocumentTemplatesAPI = () => axios.get(endpoints.DOCUMENT_TEMPLATES);
export const deleteDocumentTemplateAPI = (data) => axios.delete(endpoints.DOCUMENT_TEMPLATES, { data });
export const createDocumentTemplateAPI = (data) => axios.post(endpoints.DOCUMENT_TEMPLATES, data);
export const updateDocumentTemplateAPI = (data) => axios.put(endpoints.DOCUMENT_TEMPLATES, data);
export const uploadDocumentTemplateAPI = (data) => axios.post(endpoints.UPLOAD_DOCUMENT_TEMPLATES, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const downloadDocumentTemplateApi = (data) =>
  axios.post(endpoints.DOWNLOAD_DOCUMENT_TEMPLATES, data, {
    responseType: "blob",
  });


//Assets and discounts creation
export const getAssetsAndDiscountsCreationPayrollTypesAPI = () => axios.get(endpoints.ASSETS_DISCOUNTS_CREATION_PAYROLL_TYPES);
export const getAssetsAndDiscountsCreationAPI = (query) => axios.get(endpoints.ASSETS_DISCOUNTS_CREATION + query);
export const orderAssetsAndDiscountsCreationAPI = (data) => axios.post(endpoints.ASSETS_DISCOUNTS_CREATION_ORDER, data);
export const duplicateAssetsAndDiscountsCreationOrderAPI = (data) => axios.post(endpoints.ASSETS_DISCOUNTS_CREATION_DUPLICATE, data);
export const modifyAssetsAndDiscountsCreationAPI = (data) => axios.put(endpoints.ASSETS_DISCOUNTS_CREATION, data);
export const deleteAssetsAndDiscountsCreationAPI = (data) => axios.delete(endpoints.ASSETS_DISCOUNTS_CREATION, { data });

// Assets and discounts screen selector
export const getQuantityPerScreenAPI = () => axios.get(endpoints.ASSETS_DISCOUNTS_QUANTITY_PER_SCREEN);
export const getAutomaticProcessesAPI = () => axios.get(endpoints.ASSETS_DISCOUNTS_AUTOMATIC_PROCESSES);
export const executeAutomaticProcessAPI = (data) => axios.post(endpoints.ASSETS_DISCOUNTS_AUTOMATIC_PROCESSES, data);
export const viewAutomaticProcessAPI = (query) => axios.get(endpoints.VIEW_ASSETS_DISCOUNTS_AUTOMATIC_PROCESSES + query);

//Digital warehouse file types
export const getDigitalWarehouseFileTypesAPI = () => axios.get(endpoints.DIGITAL_WAREHOUSE_FILE_TYPES);
export const deleteDigitalWarehouseFileTypesAPI = (data) => axios.delete(endpoints.DIGITAL_WAREHOUSE_FILE_TYPES, { data });
export const createDigitalWarehouseFileTypesAPI = (data) => axios.post(endpoints.DIGITAL_WAREHOUSE_FILE_TYPES, data);
export const modifyDigitalWarehouseFileTypesAPI = (data) => axios.put(endpoints.DIGITAL_WAREHOUSE_FILE_TYPES, data);

//Scholarships
export const getSchoolingAPI = () => axios.get(endpoints.SCHOOLING);
export const deleteSchoolingAPI = (data) => axios.delete(endpoints.SCHOOLING, { data });
export const createSchoolingAPI = (data) => axios.post(endpoints.SCHOOLING, data);
export const modifySchoolingAPI = (data) => axios.put(endpoints.SCHOOLING, data);

// Holiday table
export const getHolidayTableAPI = () => axios.get(endpoints.HOLIDAY_TABLE_ENDPOINT);
export const deleteHolidayTableAPI = (data) => axios.delete(endpoints.HOLIDAY_TABLE_ENDPOINT, { data });
export const setHolidayTableAPI = (data) => axios.post(endpoints.HOLIDAY_TABLE_ENDPOINT, data);

// Extra hours table
export const getExtraHoursTableAPI = () => axios.get(endpoints.EXTRA_HOURS_TABLE_ENDPOINT);
export const deleteExtraHoursTableAPI = (data) => axios.delete(endpoints.EXTRA_HOURS_TABLE_ENDPOINT, { data });
export const setExtraHoursTableAPI = (data) => axios.post(endpoints.EXTRA_HOURS_TABLE_ENDPOINT, data);

// Festivities table
export const getFestivitiesTableAPI = () => axios.get(endpoints.FESTIVITIES_TABLE_ENDPOINT);
export const deleteFestivitiesTableAPI = (data) => axios.delete(endpoints.FESTIVITIES_TABLE_ENDPOINT, { data });
export const setFestivitiesTableAPI = (data) => axios.post(endpoints.FESTIVITIES_TABLE_ENDPOINT, data);

//studies
export const getStudiesAPI = () => axios.get(endpoints.STUDIES);
export const deleteStudyAPI = (data) => axios.delete(endpoints.STUDIES, { data });
export const createStudyAPI = (data) => axios.post(endpoints.STUDIES, data);
export const modifyStudyAPI = (data) => axios.put(endpoints.STUDIES, data);

//specializations
export const getSpecializationsAPI = () => axios.get(endpoints.SPECIALIZATIONS);
export const deleteSpecializationAPI = (data) => axios.delete(endpoints.SPECIALIZATIONS, { data });
export const createSpecializationAPI = (data) => axios.post(endpoints.SPECIALIZATIONS, data);
export const modifySpecializationAPI = (data) => axios.put(endpoints.SPECIALIZATIONS, data);

//languages
export const getLanguagesAPI = () => axios.get(endpoints.LANGUAGES);
export const deleteLanguageAPI = (data) => axios.delete(endpoints.LANGUAGES, { data });
export const createLanguageAPI = (data) => axios.post(endpoints.LANGUAGES, data);
export const modifyLanguageAPI = (data) => axios.put(endpoints.LANGUAGES, data);

//carga masiva carpeta digital
export const massiveUploadDigitalFolderEndpoint = (data) => axios.post(endpoints.MASSIVE_UPLOAD_DIGITAL_FOLDER, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});

// Carga masiva de distribucion de costo
export const downloadDistributionCostExcelApi = (data) => axios.post(endpoints.MASSIVE_DISTRIBUTION_COST_DOWNLOAD_ENDPOINT, data, {
  responseType: "blob",
});

export const uploadDistributionCostExcelApi = (data) => axios.post(endpoints.MASSIVE_DISTRIBUTION_COST_UPLOAD_ENDPOINT, data, {
  headers: {
    contentType: 'multipart/form-data',
  },
  responseType: "blob",
});

// Carga masiva de grupo familiar
export const downloadFamilyGroupExcelApi = (data) => axios.post(endpoints.MASSIVE_FAMILY_GROUP_DOWNLOAD_ENDPOINT, data, {
  responseType: "blob",
});

export const uploadFamilyGroupExcelApi = (data) => axios.post(endpoints.MASSIVE_FAMILY_GROUP_UPLOAD_ENDPOINT, data, {
  headers: {
    contentType: 'multipart/form-data',
  },
  responseType: "blob",
});

//Retirement Definition
export const getRetirementDefinitionsAPI = () => axios.get(endpoints.RETIREMENT_DEFINITION);
export const deleteRetirementDefinitionAPI = (data) => axios.delete(endpoints.RETIREMENT_DEFINITION, { data });
export const createRetirementDefinitionAPI = (data) => axios.post(endpoints.RETIREMENT_DEFINITION, data);
export const modifyRetirementDefinitionAPI = (data) => axios.put(endpoints.RETIREMENT_DEFINITION, data);

//Health Definition
export const getHealthDefinitionsAPI = () => axios.get(endpoints.HEALTH_DEFINITION);
export const deleteHealthDefinitionAPI = (data) => axios.delete(endpoints.HEALTH_DEFINITION, { data });
export const createHealthDefinitionAPI = (data) => axios.post(endpoints.HEALTH_DEFINITION, data);
export const modifyHealthDefinitionAPI = (data) => axios.put(endpoints.HEALTH_DEFINITION, data);

// Assets discounts entry per concept
export const getAssetDiscountsBasicElementsAPI = (query) => axios.get(endpoints.ASSETS_DISCOUNTS_ENTRY_PER_CONCEPT_BASIC_ELEMENTS + query);
export const getAssetDiscountsGivenEntryStructureAPI = (query) => axios.get(endpoints.ASSETS_DISCOUNTS_ENTRY_PER_CONCEPT + query);
export const setAssetDiscountsEntryPerConceptsAPI = (data) => axios.post(endpoints.ASSETS_DISCOUNTS_ENTRY_PER_CONCEPT, data);

// Assets discounts entry per concept
export const getInitialEntryPerConceptAPI = () => axios.get(endpoints.ENTRY_PER_CONCEPT_INITIAL);
export const getEntryPerConceptMyPeopleApi = () => axios.get(endpoints.ENTRY_PER_CONCEPT_MYPEOPLE);
export const getEntryPerConceptApi = (query) => axios.get(endpoints.ENTRY_PER_CONCEPT + query);
export const createEntryPerConceptApi = (data) => axios.post(endpoints.ENTRY_PER_CONCEPT, data);
export const modifyEntryPerConceptApi = (data) => axios.put(endpoints.ENTRY_PER_CONCEPT, data);
export const deleteEntryPerConceptApi = (data) => axios.delete(endpoints.ENTRY_PER_CONCEPT, { data });
export const duplicateEntryPerConceptApi = (data) => axios.post(endpoints.ENTRY_PER_CONCEPT_DUPLICATE, data);

// Assets discounts entry per society
export const getAssetDiscountsBasicElementsPerSocietyAPI = () => axios.get(endpoints.ASSETS_DISCOUNTS_ENTRY_PER_SOCIETY_BASIC_ELEMENTS);
export const getAssetDiscountsGivenEntryStructurePerSocietyAPI = (query) => axios.get(endpoints.ASSETS_DISCOUNTS_ENTRY_PER_SOCIETY + query);
export const setAssetDiscountsEntryPerSocietyAPI = (data) => axios.post(endpoints.ASSETS_DISCOUNTS_ENTRY_PER_SOCIETY, data);

// Actions
export const getActionsAPI = () => axios.get(endpoints.ACTIONS);
export const deleteActionsItemAPI = (data) => axios.delete(endpoints.ACTIONS, { data });
export const createActionsItemAPI = (data) => axios.post(endpoints.ACTIONS, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const modifyActionsItemAPI = (data) => axios.put(endpoints.ACTIONS, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});

//Digital Warehouse
export const getDigitalWarehouseAPI = (query) => axios.get(endpoints.DIGITAL_WAREHOUSE + query);
export const deleteDigitalWarehouseItemAPI = (data) => axios.delete(endpoints.DIGITAL_WAREHOUSE, { data });
export const createDigitalWarehouseItemAPI = (data) => axios.post(endpoints.DIGITAL_WAREHOUSE, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const modifyDigitalWarehouseItemAPI = (data) => axios.put(endpoints.DIGITAL_WAREHOUSE, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const downloadDigitalWarehouseItemApi = (data) =>
  axios.post(endpoints.DIGITAL_WAREHOUSE_DOWNLOAD, data, {
    responseType: "blob",
  });

//Family asignations
export const getFamilyAsignationsAPI = () => axios.get(endpoints.FAMILY_ASIGNATIONS);
export const deleteFamilyAsignationAPI = (data) => axios.delete(endpoints.FAMILY_ASIGNATIONS, { data });
export const createFamilyAsignationAPI = (data) => axios.post(endpoints.FAMILY_ASIGNATIONS, data);
export const modifyFamilyAsignationAPI = (data) => axios.put(endpoints.FAMILY_ASIGNATIONS, data);

//Indices
export const getRatingsAPI = () => axios.get(endpoints.RATINGS);
export const deleteRatingAPI = (data) => axios.delete(endpoints.RATINGS, { data });
export const createRatingAPI = (data) => axios.post(endpoints.RATINGS, data);
export const modifyRatingAPI = (data) => axios.put(endpoints.RATINGS, data);
export const duplicateRatingAPI = (data) => axios.post(endpoints.DUPLICATE_RATINGS, data);

//General values
export const getTaxTableAPI = () => axios.get(endpoints.TAX_TABLE);
export const deleteTaxTableAPI = (data) => axios.delete(endpoints.TAX_TABLE, { data });
export const setTaxTableAPI = (data) => axios.post(endpoints.TAX_TABLE, data);

// visualizacion de resumen
export const getAssetsDiscountsSummaryAPI = (query) => axios.get(endpoints.ASSETS_DISCOUNTS_SUMMARY + query);
export const deleteAssetsDiscountsSummaryAPI = (data) => axios.delete(endpoints.ASSETS_DISCOUNTS_SUMMARY, { data });

// Reporte de asistencia
export const getAttendanceReportAPI = () => axios.get(endpoints.ATTENDANCE_REPORT);

// Assets discounts entry per concept set
export const getAssetDiscountsBasicElementsConceptSetAPI = () => axios.get(endpoints.ASSETS_DISCOUNTS_ENTRY_PER_CONCEPT_SET_BASIC_ELEMENTS);
export const getAssetDiscountsGivenConceptSetAPI = (query) => axios.get(endpoints.ASSETS_DISCOUNTS_ENTRY_PER_CONCEPT_SET + query);
export const setAssetDiscountsEntryPerConceptsSetAPI = (data) => axios.post(endpoints.ASSETS_DISCOUNTS_ENTRY_PER_CONCEPT_SET, data);

// Assents discounts entry by import
export const entryByImportAPI = (data) => axios.post(endpoints.ASSETS_DISCOUNTS_ENTRY_BY_IMPORT, data, {
  headers: {
    contentType: 'multipart/form-data',
  },
  responseType: "blob",
});

// Import structures API
export const importStructuresAPI = (data) => axios.post(endpoints.IMPORT_STRUCTURES, data, {
  headers: {
    contentType: 'multipart/form-data',
  },
});

//Third party files
export const getSpecialProcessDefinitionAPI = () => axios.get(endpoints.SPECIAL_PROCESS_DEFINITION_ENDPOINT);
export const deleteSpecialProcessDefinitionAPI = (data) => axios.delete(endpoints.SPECIAL_PROCESS_DEFINITION_ENDPOINT, { data });
export const createSpecialProcessDefinitionAPI = (data) => axios.post(endpoints.SPECIAL_PROCESS_DEFINITION_ENDPOINT, data);
export const modifySpecialProcessDefinitionAPI = (data) => axios.put(endpoints.SPECIAL_PROCESS_DEFINITION_ENDPOINT, data);

export const downloadImportStructureApi = (data) =>
  axios.post(endpoints.DOWNLOAD_IMPORT_STRUCTURE, data, {
    responseType: "blob",
  });

//Imputaciones contables
export const getAccountingImputationsAPI = () => axios.get(endpoints.ACCOUNTING_IMPUTATIONS);
export const deleteAccountingImputationsAPI = (data) => axios.delete(endpoints.ACCOUNTING_IMPUTATIONS, { data });
export const createAccountingImputationsAPI = (data) => axios.post(endpoints.ACCOUNTING_IMPUTATIONS, data);
export const modifyAccountingImputationsAPI = (data) => axios.put(endpoints.ACCOUNTING_IMPUTATIONS, data);
export const duplicateAccountingImputationsAPI = (data) => axios.post(endpoints.DUPLICATE_ACCOUNTING_IMPUTATIONS, data);

//Third party files definition parameters
export const getSpecialProcessDefinitionParametersAPI = (query) => axios.get(endpoints.SPECIAL_PROCESS_PARAMETERS_DEFINITION_ENDPOINT + query);
export const createSpecialProcessDefinitionParameterAPI = (data) => axios.post(endpoints.SPECIAL_PROCESS_PARAMETERS_DEFINITION_ENDPOINT, data);
export const modifySpecialProcessDefinitionParameterAPI = (data) => axios.put(endpoints.SPECIAL_PROCESS_PARAMETERS_DEFINITION_ENDPOINT, data);
export const deleteSpecialProcessDefinitionParameterAPI = (data) => axios.delete(endpoints.SPECIAL_PROCESS_PARAMETERS_DEFINITION_ENDPOINT, { data });
export const duplicateSpecialProcessDefinitionParameterAPI = (data) => axios.post(endpoints.DUPLICATE_SPECIAL_PROCESS_PARAMETERS_DEFINITION_ENDPOINT, data);

// Nine Box classification
export const getInitialNineBoxClassificationAPI = () => axios.get(endpoints.NINE_BOX_CLASSIFICATION_INITIAL_ENDPOINT);
export const getNineBoxClassificationAPI = (data) => axios.post(endpoints.NINE_BOX_CLASSIFICATION_LIST_ENDPOINT, data);
export const modifyNineBoxClassificationAPI = (data) => axios.put(endpoints.NINE_BOX_CLASSIFICATION_ENDPOINT, data);
export const deleteNineBoxClassificationAPI = (data) => axios.delete(endpoints.NINE_BOX_CLASSIFICATION_ENDPOINT, { data });
export const createNineBoxClassificationAPI = (data) => axios.post(endpoints.NINE_BOX_CLASSIFICATION_ENDPOINT, data);


// process scheduling
export const getProcessSchedulingAPI = () => axios.get(endpoints.PROCESS_SCHEDULING_ENDPOINT);
export const createProcessSchedulingAPI = (data) => axios.post(endpoints.PROCESS_SCHEDULING_ENDPOINT, data);
export const updateProcessSchedulingAPI = (data) => axios.put(endpoints.PROCESS_SCHEDULING_ENDPOINT, data);
export const deleteProcessSchedulingAPI = (data) => axios.delete(endpoints.PROCESS_SCHEDULING_ENDPOINT, { data });

// accounting entries
export const getAccountingEntriesAPI = (query) => axios.get(endpoints.ACCOUNTING_ENTRIES_ENDPOINT + query);

export const downloadDesktopR8Api = (data) =>
  axios.post(endpoints.DOWNLOAD_R8_DESKTOP_ENDPOINT, data, {
    responseType: "blob",
  });

// favoritos
export const getFavouriteFunctionsAPI = (query) => axios.get(endpoints.FAVOURITE_FUNCTIONS_ENDPOINT + query);
export const modifyFavouriteFunctionActionAPI = (data) => axios.post(endpoints.FAVOURITE_FUNCTIONS_ENDPOINT, data);

// visited page
export const setPageAsVisitedAPI = (data) => axios.post(endpoints.VISITED_PAGE_ENDPOINT, data);

export const getMyDesktopAPI = () => axios.get(endpoints.MY_DESKTOP_ENDPOINT);
export const getAbsentCollaboratorsAPI = () => axios.get(endpoints.ABSENT_COLLABORATORS_ENDPOINT);
export const acceptMyConsentAPI = (data) => axios.post(endpoints.ACCEPT_MY_CONSENT_ENDPOINT, data);
export const previewMyConsentAPI = (data) => axios.post(endpoints.PREVIEW_MY_CONSENT_ENDPOINT, data, { responseType: "blob" });

// special process execution
export const getSpecialProcessExecutionAPI = () => axios.get(endpoints.SPECIAL_PROCESS_EXECUTION);
export const executeSpecialProcessAPI = (data) => axios.post(endpoints.SPECIAL_PROCESS_EXECUTION, data);
export const getSpecialProcessQueryAPI = (query) => axios.get(endpoints.SPECIAL_PROCESS_QUERY + query);

// my people schedule
export const getMyPeopleScheduleAPI = (query) => axios.get(endpoints.MY_PEOPLE_SCHEDULE + query);
export const createMyPeopleScheduleAPI = (data) => axios.post(endpoints.MY_PEOPLE_SCHEDULE, data);

// my schedule
export const getMyScheduleAPI = () => axios.get(endpoints.MY_SCHEDULE);
export const createMyScheduleAPI = (data) => axios.post(endpoints.MY_SCHEDULE, data);

// rebuild accumulators
export const rebuildAccumulatorsAPI = (data) => axios.post(endpoints.REBUILD_ACCUMULATORS, data);

// turn definition
export const getTurnDefinitionAPI = () => axios.get(endpoints.TURN_DEFINITION_ENDPOINT);
export const createTurnDefinitionAPI = (data) => axios.post(endpoints.TURN_DEFINITION_ENDPOINT, data);
export const updateTurnDefinitionAPI = (data) => axios.put(endpoints.TURN_DEFINITION_ENDPOINT, data);
export const deleteTurnDefinitionAPI = (data) => axios.delete(endpoints.TURN_DEFINITION_ENDPOINT, { data });

// schedules definition
export const getSchedulesDefinitionAPI = () => axios.get(endpoints.SCHEDULES_DEFINITION_ENDPOINT);
export const createSchedulesDefinitionAPI = (data) => axios.post(endpoints.SCHEDULES_DEFINITION_ENDPOINT, data);
export const updateSchedulesDefinitionAPI = (data) => axios.put(endpoints.SCHEDULES_DEFINITION_ENDPOINT, data);
export const deleteSchedulesDefinitionAPI = (data) => axios.delete(endpoints.SCHEDULES_DEFINITION_ENDPOINT, { data });

// Schedules Programming
export const getSchedulesProgrammingAPI = () => axios.get(endpoints.SCHEDULES_PROGRAMMING_ENDPOINT);
export const createSchedulesProgrammingAPI = (data) => axios.post(endpoints.SCHEDULES_PROGRAMMING_ENDPOINT, data);
export const updateSchedulesProgrammingAPI = (data) => axios.put(endpoints.SCHEDULES_PROGRAMMING_ENDPOINT, data);
export const deleteSchedulesProgrammingAPI = (data) => axios.delete(endpoints.SCHEDULES_PROGRAMMING_ENDPOINT, { data });

//carga masiva fotografias
export const massiveUploadMyPeoplePhotosApi = (data) => axios.post(endpoints.MASSIVE_UPLOAD_PHOTOS_ENDPOINT, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});

export const massiveSalaryChangeApi = (data) => axios.put(endpoints.MASSIVE_SALARY_CHANGE_ENDPOINT, data);
export const massiveTerminationApi = (data) => axios.put(endpoints.MASSIVE_TERMINATION_ENDPOINT, data);
export const massiveWorkingDayApi = (data) => axios.put(endpoints.MASSIVE_WORKING_DAY_ENDPOINT, data);
export const massiveContractApi = (data) => axios.put(endpoints.MASSIVE_CONTRACT_ENDPOINT, data);
export const massiveWorkOrChoreChangeApi = (data) => axios.put(endpoints.MASSIVE_WORK_OR_CHORE_ENDPOINT, data);
export const massivePositionChangeApi = (data) => axios.put(endpoints.MASSIVE_POSITION_CHANGE_ENDPOINT, data);
export const massivePositionRecomposeChangeApi = (data) => axios.put(endpoints.MASSIVE_POSITION_RECOMPOSE_CHANGE_ENDPOINT, data);
export const massivePayrollChangeApi = (data) => axios.put(endpoints.MASSIVE_PAYROLL_ENDPOINT, data);
export const massiveTurnChangeApi = (data) => axios.put(endpoints.MASSIVE_TURN_ENDPOINT, data);
export const massiveMarkingSystemChangeApi = (data) => axios.put(endpoints.MASSIVE_MARKING_SYSTEM_ENDPOINT, data);
export const massiveRehiringChangeApi = (data) => axios.put(endpoints.MASSIVE_REHIRING_ENDPOINT, data);
export const massiveStructureChangeApi = (data) => axios.put(endpoints.MASSIVE_STRUCTURE_ENDPOINT, data);
export const employeeCodeChangeApi = (data) => axios.put(endpoints.UPDATE_CODE_ENDPOINT, data);
export const massiveCostCenterApi = (data) => axios.put(endpoints.MASSIVE_COST_CENTER_ENDPOINT, data);
export const getMassiveOnboardingAssignmentDataAPI = () => axios.get(endpoints.MASSIVE_ONBOARDING_ASSIGNMENT_ENDPOINT);
export const massiveOnboardingAssignmentAPI = (data) => axios.put(endpoints.MASSIVE_ONBOARDING_ASSIGNMENT_ENDPOINT, data);

// acumuladores historicos
export const getHistoricAccumulatorsApi = (query) => axios.get(endpoints.HISTORIC_ACCUMULATORS_ENDPOINT + query);
export const getMatchHistoricAccumulatorsApi = (query) => axios.get(endpoints.FIND_MATCH_HISTORIC_ACCUMULATORS_ENDPOINT + query);
export const createHistoricAccumulatorsApi = (data) => axios.post(endpoints.HISTORIC_ACCUMULATORS_ENDPOINT, data);
export const updateHistoricAccumulatorsApi = (data) => axios.put(endpoints.HISTORIC_ACCUMULATORS_ENDPOINT, data);

// Cuotas
export const getAssetsDiscountsEntryByInstallmentsAPI = (query) => axios.get(endpoints.ASSETS_DISCOUNTS_ENTRY_BY_INSTALLMENTS + query);
export const createAssetsDiscountsEntryByInstallmentAPI = (data) => axios.post(endpoints.ASSETS_DISCOUNTS_ENTRY_BY_INSTALLMENTS, data);
export const modifyAssetsDiscountsEntryByInstallmentAPI = (data) => axios.put(endpoints.ASSETS_DISCOUNTS_ENTRY_BY_INSTALLMENTS, data);
export const deleteAssetsDiscountsEntryByInstallmentAPI = (data) => axios.delete(endpoints.ASSETS_DISCOUNTS_ENTRY_BY_INSTALLMENTS, { data });
export const initialGetAssetsDiscountsEntryByInstallmentsAPI = () => axios.get(endpoints.ASSETS_DISCOUNTS_INITIAL_GET_ENTRY_BY_INSTALLMENTS)

export const getSpreadsheetApi = (data) => axios.post(endpoints.HISTORIC_ACCUMULATORS_SPREADSHEET_ENDPOINT, data, {
  responseType: "blob",
});
export const importSpreadsheetApi = (data) => axios.post(endpoints.HISTORIC_ACCUMULATORS_IMPORT_SPREADSHEET_ENDPOINT, data, {
  headers: {
    contentType: 'multipart/form-data',
  },
  responseType: "blob",
});

//Alerts templates
export const getAlertsTemplatesAPI = () => axios.get(endpoints.ALERTS_TEMPLATES_ENDPOINT);
export const createAlertsTemplatesAPI = (data) => axios.post(endpoints.ALERTS_TEMPLATES_ENDPOINT, data);
export const updateAlertsTemplatesAPI = (data) => axios.put(endpoints.ALERTS_TEMPLATES_ENDPOINT, data);
export const deleteAlertsTemplatesAPI = (data) => axios.delete(endpoints.ALERTS_TEMPLATES_ENDPOINT, { data });

// Definicion de calendarios
export const getCalendarDefinitionApi = () => axios.get(endpoints.CALENDAR_DEFINITION_ENDPOINT);
export const createCalendarDefinitionApi = (data) => axios.post(endpoints.CALENDAR_DEFINITION_ENDPOINT, data);
export const updateCalendarDefinitionApi = (data) => axios.put(endpoints.CALENDAR_DEFINITION_ENDPOINT, data);
export const deleteCalendarDefinitionAPI = (data) => axios.delete(endpoints.CALENDAR_DEFINITION_ENDPOINT, { data });
export const downloadCalendarDefinitionExcelApi = (data) => axios.post(endpoints.DOWNLOAD_CALENDAR_DEFINITION_ENDPOINT, data, {
  responseType: "blob",
});
export const importCalendarDefinitionExcelApi = (data) => axios.post(endpoints.IMPORT_CALENDAR_DEFINITION_ENDPOINT, data, {
  headers: {
    contentType: 'multipart/form-data',
  },
  responseType: "blob",
});

// Horas extras
export const getExtraHoursAuthorizationAPI = (query) => axios.get(endpoints.EXTRA_HOURS_AUTHORIZATION + query)
export const setExtraHoursAuthorizationAPI = (data) => axios.post(endpoints.EXTRA_HOURS_AUTHORIZATION, data);
export const downloadImportStructureExtraHoursAuthorizationAPI = (data) => axios.post(endpoints.EXPORT_EXTRA_HOURS_AUTHORIZATION_STRUCTURE, data, {
  responseType: "blob",
});
export const importStructureExtraHoursAuthorizationAPI = (data) => axios.post(endpoints.IMPORT_EXTRA_HOURS_AUTHORIZATION_STRUCTURE, data, {
  headers: {
    contentType: 'multipart/form-data',
  },
  responseType: "blob",
});

// issue summary
export const getIssueSummaryApi = (query) => axios.get(endpoints.ISSUE_SUMMARY_ENDPOINT + query);
export const getIssueSummaryMatrixApi = (query) => axios.get(endpoints.ISSUE_SUMMARY_MATRIX_ENDPOINT + query);

// issues licences
export const getIssueLicencesApi = () => axios.get(endpoints.ISSUES_LICENCES_ENDPOINT);
export const createIssueLicenceApi = (data) => axios.post(endpoints.ISSUES_LICENCES_ENDPOINT, data);
export const updateIssueLicenceApi = (data) => axios.put(endpoints.ISSUES_LICENCES_ENDPOINT, data);
export const deleteIssueLicenceAPI = (data) => axios.delete(endpoints.ISSUES_LICENCES_ENDPOINT, { data });
export const downloadIssueLicencesExcelApi = (data) => axios.post(endpoints.ISSUES_LICENCES_DOWNLOAD_ENDPOINT, data, {
  responseType: "blob",
});
export const importIssueLicencesExcelApi = (data) => axios.post(endpoints.ISSUES_LICENCES_UPLOAD_ENDPOINT, data, {
  headers: {
    contentType: 'multipart/form-data',
  },
  responseType: "blob",
});

// issues permissions
export const getIssuePermissionsApi = () => axios.get(endpoints.ISSUES_PERMISSIONS_ENDPOINT);
export const getIssuePermissionsBalanceApi = (query) => axios.get(endpoints.ISSUES_PERMISSIONS_BALANCE_ENDPOINT + query);
export const createIssuePermissionsBalanceApi = (data) => axios.post(endpoints.ISSUES_PERMISSIONS_BALANCE_ENDPOINT, data);
export const deleteIssuePermissionsBalanceApi = (data) => axios.delete(endpoints.ISSUES_PERMISSIONS_BALANCE_ENDPOINT, { data });
export const generateFileFromIssuePermissionApi = (data) => axios.post(endpoints.ISSUES_PERMISSIONS_GENERATE_FILE_ENDPOINT, data);
export const createIssuePermissionApi = (data) => axios.post(endpoints.ISSUES_PERMISSIONS_ENDPOINT, data);
export const updateIssuePermissionApi = (data) => axios.put(endpoints.ISSUES_PERMISSIONS_ENDPOINT, data);
export const deleteIssuePermissionAPI = (data) => axios.delete(endpoints.ISSUES_PERMISSIONS_ENDPOINT, { data });
export const downloadIssuePermissionsExcelApi = (data) => axios.post(endpoints.ISSUES_PERMISSIONS_DOWNLOAD_ENDPOINT, data, {
  responseType: "blob",
});
export const importIssuePermissionsExcelApi = (data) => axios.post(endpoints.ISSUES_PERMISSIONS_UPLOAD_ENDPOINT, data, {
  headers: {
    contentType: 'multipart/form-data',
  },
  responseType: "blob",
});

// issues absences
export const getIssueAbsencesApi = () => axios.get(endpoints.ISSUES_ABSENCES_ENDPOINT);
export const createIssueAbsenceApi = (data) => axios.post(endpoints.ISSUES_ABSENCES_ENDPOINT, data);
export const updateIssueAbsenceApi = (data) => axios.put(endpoints.ISSUES_ABSENCES_ENDPOINT, data);
export const deleteIssueAbsenceAPI = (data) => axios.delete(endpoints.ISSUES_ABSENCES_ENDPOINT, { data });
export const downloadIssueAbsencesExcelApi = (data) => axios.post(endpoints.ISSUES_ABSENCES_DOWNLOAD_ENDPOINT, data, {
  responseType: "blob",
});
export const importIssueAbsencesExcelApi = (data) => axios.post(endpoints.ISSUES_ABSENCES_UPLOAD_ENDPOINT, data, {
  headers: {
    contentType: 'multipart/form-data',
  },
  responseType: "blob",
});

// issues vacations
export const getIssueVacationsApi = () => axios.get(endpoints.ISSUES_VACATIONS_ENDPOINT);
export const createIssueVacationApi = (data) => axios.post(endpoints.ISSUES_VACATIONS_ENDPOINT, data);
export const updateIssueVacationApi = (data) => axios.put(endpoints.ISSUES_VACATIONS_ENDPOINT, data);
export const deleteIssueVacationAPI = (data) => axios.delete(endpoints.ISSUES_VACATIONS_ENDPOINT, { data });
export const generateFileFromIssueVacationApi = (data) => axios.post(endpoints.ISSUES_VACATIONS_GENERATE_FILE_ENDPOINT, data);
export const downloadIssueVacationsExcelApi = (data) => axios.post(endpoints.ISSUES_VACATIONS_DOWNLOAD_ENDPOINT, data, {
  responseType: "blob",
});
export const importIssueVacationsExcelApi = (data) => axios.post(endpoints.ISSUES_VACATIONS_UPLOAD_ENDPOINT, data, {
  headers: {
    contentType: 'multipart/form-data',
  },
  responseType: "blob",
});

//tipos de consentimiento
export const getConsentTypessAPI = () => axios.get(endpoints.CONSENT_TYPES);
export const deleteConsentTypeAPI = (data) => axios.delete(endpoints.CONSENT_TYPES, { data });
export const createConsentTypeAPI = (data) => axios.post(endpoints.CONSENT_TYPES, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const updateConsentTypeAPI = (data) => axios.put(endpoints.CONSENT_TYPES, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const uploadConsentTypeAPI = (data) => axios.post(endpoints.UPLOAD_CONSENT_TYPES, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const downloadConsentTypeApi = (data) =>
  axios.post(endpoints.DOWNLOAD_CONSENT_TYPES, data, {
    responseType: "blob",
  });


// benefits center  
export const getBenefitsCenterApi = () => axios.get(endpoints.BENEFITS_CENTER_ENDPOINT);
export const createBenefitsCenterApi = (data) => axios.post(endpoints.BENEFITS_CENTER_ENDPOINT, data);
export const updateBenefitsCenterApi = (data) => axios.put(endpoints.BENEFITS_CENTER_ENDPOINT, data);
export const deleteBenefitsCenterApi = (data) => axios.delete(endpoints.BENEFITS_CENTER_ENDPOINT, { data });

// projects
export const getProjectsApi = () => axios.get(endpoints.PROJECTS_ENDPOINT);
export const createProjectApi = (data) => axios.post(endpoints.PROJECTS_ENDPOINT, data);
export const updateProjectApi = (data) => axios.put(endpoints.PROJECTS_ENDPOINT, data);
export const deleteProjectApi = (data) => axios.delete(endpoints.PROJECTS_ENDPOINT, { data });

// consent publication
export const getConsentPublicationsApi = () => axios.get(endpoints.CONSENT_PUBLICATION_ENDPOINT);
export const createConsentPublicationApi = (data) => axios.post(endpoints.CONSENT_PUBLICATION_ENDPOINT, data);
export const updateConsentPublicationApi = (data) => axios.put(endpoints.CONSENT_PUBLICATION_ENDPOINT, data);
export const deleteConsentPublicationApi = (data) => axios.delete(endpoints.CONSENT_PUBLICATION_ENDPOINT, { data });

// Family Group
export const getFamilyGroupApi = (query) => axios.get(endpoints.FAMILY_GROUP_ENDPOINT + query);
export const createFamilyGroupApi = (data) => axios.post(endpoints.FAMILY_GROUP_ENDPOINT, data);
export const updateFamilyGroupApi = (data) => axios.put(endpoints.FAMILY_GROUP_ENDPOINT, data);
export const deleteFamilyGroupAPI = (data) => axios.delete(endpoints.FAMILY_GROUP_ENDPOINT, { data });

export const getIndividualPayrollChangeApi = (query) => axios.get(endpoints.PAYROLLS_ENDPOINT + query);
export const updateIndividualPayrollChangeApi = (data) => axios.put(endpoints.PAYROLLS_ENDPOINT, data);

// distribution cost
export const getDistributionCostApi = (query) => axios.get(endpoints.DISTRIBUTION_COST_ENDPOINT + query);
export const saveDistributionCostApi = (data) => axios.post(endpoints.DISTRIBUTION_COST_ENDPOINT, { data });

// historical periods
export const getHistoricalPeriodsApi = () => axios.get(endpoints.HISTORICAL_PERIODS_ENDPOINT);
export const getHistoricalPeriodsByPeriodApi = (query) => axios.get(endpoints.HISTORICAL_PERIODS_BY_PERIOD_ENDPOINT + query);

// my approved consents
export const getMyApprovedConsentsApi = () => axios.get(endpoints.MY_APPROVED_CONSENTS_ENDPOINT);

export const getQueriesGivenLevelApi = (query) => axios.get(endpoints.QUERIES_GIVEN_LEVEL_ENDPOINT + query);

// Processes by path
export const getProcessesbyPathApi = (query) => axios.get(endpoints.GET_PROCESSES_BY_PATH_ENDPOINT + query);
export const executeProcessesbyPathApi = (data) => axios.post(endpoints.EXECUTE_PROCESSES_BY_PATH_ENDPOINT, data);

// User tables definition
export const getUserTablesDefinitionAPI = () => axios.get(endpoints.USER_TABLES_DEFINITION_ENDPOINT);
export const createUserTableDefinitionAPI = (data) => axios.post(endpoints.USER_TABLES_DEFINITION_ENDPOINT, data);
export const updateUserTableDefinitionAPI = (data) => axios.put(endpoints.USER_TABLES_DEFINITION_ENDPOINT, data);
export const deleteUserTableDefinitionAPI = (data) => axios.delete(endpoints.USER_TABLES_DEFINITION_ENDPOINT, { data });
export const duplicateUserTableDefinitionAPI = (data) => axios.post(endpoints.USER_TABLES_DEFINITION_DUPLICATE_ENDPOINT, data);
export const exportUserTableDefinitionAPI = (data) => axios.post(endpoints.USER_TABLES_DEFINITION_EXPORT, data);
export const importUserTableDefinitionAPI = (data) => axios.post(endpoints.USER_TABLES_DEFINITION_IMPORT, data);

// User tables field definition
export const getUserTablesFieldsDefinitionAPI = (query) => axios.get(endpoints.USER_TABLES_DEFINITION_FIELDS_ENDPOINT + query);
export const createUserTableFieldsDefinitionAPI = (data) => axios.post(endpoints.USER_TABLES_DEFINITION_FIELDS_ENDPOINT, data);
export const updateUserTableFieldsDefinitionAPI = (data) => axios.put(endpoints.USER_TABLES_DEFINITION_FIELDS_ENDPOINT, data);
export const deleteUserTableFieldsDefinitionAPI = (data) => axios.delete(endpoints.USER_TABLES_DEFINITION_FIELDS_ENDPOINT, { data });

// catalogs
export const getCatalogsApi = () => axios.get(endpoints.CATALOGS_ENDPOINT);
export const getCatalogFieldsApi = (query) => axios.get(endpoints.CATALOGS_FIELDS_ENDPOINT + query);
export const enableSystemTableEditorsApi = (data) => axios.post(endpoints.ENABLE_SYSTEM_TABLE_EDITORS_ENDPOINT, data);

// User tables
export const getUserTablesAPI = () => axios.get(endpoints.USER_TABLES_ENDPOINT);
export const getUserTableFormAPI = (query) => axios.get(endpoints.USER_TABLES_FORM_ENDPOINT + query);
export const createUserTableFormAPI = (data) => axios.post(endpoints.USER_TABLES_FORM_ENDPOINT, data);
export const updateUserTableFormAPI = (data) => axios.put(endpoints.USER_TABLES_FORM_ENDPOINT, data);
export const deleteUserTableFormAPI = (data) => axios.delete(endpoints.USER_TABLES_FORM_ENDPOINT, { data });
export const executeUserTableFormAPI = (data) => axios.post(endpoints.USER_TABLES_EXECUTE_ENDPOINT, data);
export const downloadUserTableExcelApi = (data) => axios.post(endpoints.USER_TABLES_DOWNLOAD_ENDPOINT, data, {
  responseType: "blob",
});
export const importUserTableExcelApi = (data) => axios.post(endpoints.USER_TABLES_UPLOAD_ENDPOINT, data, {
  headers: {
    contentType: 'multipart/form-data',
  },
  responseType: "blob",
});
export const changeCheckUserTableFormAPI = (data) => axios.post(endpoints.USER_TABLES_FORM_CHANGE_CHECK_ENDPOINT, data);
export const uploadUserTableFormFileAPI = (data) => axios.post(endpoints.USER_TABLES_FORM_UPLOAD_FILE_ENDPOINT, data);

// Definicion de tablas relacionadas
export const getRelatedTablesDefinitionApi = () => axios.get(endpoints.RELATED_TABLES_DEFINITION_ENDPOINT);
export const createRelatedTableDefinitionApi = (data) => axios.post(endpoints.RELATED_TABLES_DEFINITION_ENDPOINT, data);
export const updateRelatedTableDefinitionApi = (data) => axios.put(endpoints.RELATED_TABLES_DEFINITION_ENDPOINT, data);
export const deleteRelatedTableDefinitionAPI = (data) => axios.delete(endpoints.RELATED_TABLES_DEFINITION_ENDPOINT, { data });

// System tables definition
export const getSystemTablesDefinitionAPI = () => axios.get(endpoints.SYSTEM_TABLES_DEFINITION_ENDPOINT);
export const createSystemTableDefinitionAPI = (data) => axios.post(endpoints.SYSTEM_TABLES_DEFINITION_ENDPOINT, data);
export const updateSystemTableDefinitionAPI = (data) => axios.put(endpoints.SYSTEM_TABLES_DEFINITION_ENDPOINT, data);
export const deleteSystemTableDefinitionAPI = (data) => axios.delete(endpoints.SYSTEM_TABLES_DEFINITION_ENDPOINT, { data });
export const duplicateSystemTableDefinitionAPI = (data) => axios.post(endpoints.SYSTEM_TABLES_DEFINITION_DUPLICATE_ENDPOINT, data);
export const exportSystemTableDefinitionAPI = (data) => axios.post(endpoints.SYSTEM_TABLES_DEFINITION_EXPORT, data);
export const importSystemTableDefinitionAPI = (data) => axios.post(endpoints.SYSTEM_TABLES_DEFINITION_IMPORT, data);

// System tables field definition
export const getSystemTablesFieldsDefinitionAPI = (query) => axios.get(endpoints.SYSTEM_TABLES_DEFINITION_FIELDS_ENDPOINT + query);
export const createSystemTableFieldsDefinitionAPI = (data) => axios.post(endpoints.SYSTEM_TABLES_DEFINITION_FIELDS_ENDPOINT, data);
export const updateSystemTableFieldsDefinitionAPI = (data) => axios.put(endpoints.SYSTEM_TABLES_DEFINITION_FIELDS_ENDPOINT, data);
export const deleteSystemTableFieldsDefinitionAPI = (data) => axios.delete(endpoints.SYSTEM_TABLES_DEFINITION_FIELDS_ENDPOINT, { data });

// System tables
export const getSystemTablesAPI = () => axios.get(endpoints.SYSTEM_TABLES_ENDPOINT);
export const getSystemTableFormAPI = (query) => axios.get(endpoints.SYSTEM_TABLES_FORM_ENDPOINT + query);
export const createSystemTableFormAPI = (data) => axios.post(endpoints.SYSTEM_TABLES_FORM_ENDPOINT, data);
export const updateSystemTableFormAPI = (data) => axios.put(endpoints.SYSTEM_TABLES_FORM_ENDPOINT, data);
export const deleteSystemTableFormAPI = (data) => axios.delete(endpoints.SYSTEM_TABLES_FORM_ENDPOINT, { data });
export const executeSystemTableFormAPI = (data) => axios.post(endpoints.SYSTEM_TABLES_EXECUTE_ENDPOINT, data);
export const downloadSystemTableExcelApi = (data) => axios.post(endpoints.SYSTEM_TABLES_DOWNLOAD_ENDPOINT, data, {
  responseType: "blob",
});
export const importSystemTableExcelApi = (data) => axios.post(endpoints.SYSTEM_TABLES_UPLOAD_ENDPOINT, data, {
  headers: {
    contentType: 'multipart/form-data',
  },
  responseType: "blob",
});
export const changeCheckSystemTableFormAPI = (data) => axios.post(endpoints.SYSTEM_TABLES_FORM_CHANGE_CHECK_ENDPOINT, data);
export const uploadSystemTableFormFileAPI = (data) => axios.post(endpoints.SYSTEM_TABLES_FORM_UPLOAD_FILE_ENDPOINT, data);

// transaction log
export const getTransactionLogAPI = () => axios.get(endpoints.TRANSACTION_LOG_ENDPOINT);
export const searchTransactionLogAPI = (query) => axios.get(endpoints.TRANSACTION_LOG_SEARCH_ENDPOINT + query);

// assistance analysis
export const getAssistanceAnalysisAPI = () => axios.get(endpoints.GET_ASSISTANCE_ANALYSIS_ENDPOINT);
export const getAssistanceAnalysisTotalsAPI = (query) => axios.get(endpoints.GET_ASSISTANCE_ANALYSIS_TOTALS_ENDPOINT + query);
export const getAssistanceAnalysisIndividualAPI = (query) => axios.get(endpoints.GET_ASSISTANCE_ANALYSIS_INDIVIDUAL_ENDPOINT + query);
export const executeAssistanceAnalysisTotalsAPI = (data) => axios.post(endpoints.EXECUTE_ASSISTANCE_ANALYSIS_TOTALS_ENDPOINT, data);
export const executeAssistanceAnalysisIndividualAPI = (data) => axios.post(endpoints.EXECUTE_ASSISTANCE_ANALYSIS_INDIVIDUAL_ENDPOINT, data);
export const updateObservedAssistanceAnalysisIndividualAPI = (data) => axios.put(endpoints.ASSISTANCE_ANALYSIS_INDIVIDUAL_UPDATE_OBSERVED_ENDPOINT, data);
export const getChangeScheduleAssistanceAnalysisIndividualAPI = (query) => axios.get(endpoints.ASSISTANCE_ANALYSIS_INDIVIDUAL_CHANGE_SCHEDULE_ENDPOINT + query);
export const updateChangeScheduleAssistanceAnalysisIndividualAPI = (data) => axios.put(endpoints.ASSISTANCE_ANALYSIS_INDIVIDUAL_CHANGE_SCHEDULE_ENDPOINT, data);
export const updateExtraHoursAssistanceAnalysisIndividualAPI = (data) => axios.put(endpoints.ASSISTANCE_ANALYSIS_INDIVIDUAL_AUTH_EXTRA_HOURS_ENDPOINT, data);
export const updateJustifyAbsenceAssistanceAnalysisIndividualAPI = (data) => axios.put(endpoints.ASSISTANCE_ANALYSIS_INDIVIDUAL_JUSTIFY_ABSENCE_ENDPOINT, data);
export const getAssistanceAnalysisDashboardFromServerAPI = (query) => axios.get(endpoints.GET_ASSISTANCE_ANALYSIS_TOTALS_DASHBOARD_ENDPOINT + query, {
  headers: {
    'Cache-Control': 'max-age=3, must-revalidate',
  }
});
export const getSocietiesAPI = (data) => axios.post(endpoints.AVAILABLE_SOCIETIES_ENDPOINT, data);



// Types of Score
export const getTypesOfScoreAPI = () => axios.get(endpoints.TYPES_OF_SCORE);
export const deleteTypesOfScoreAPI = (data) => axios.delete(endpoints.TYPES_OF_SCORE, { data });
export const createTypesOfScoreAPI = (data) => axios.post(endpoints.TYPES_OF_SCORE, data);
export const modifyTypesOfScoreAPI = (data) => axios.put(endpoints.TYPES_OF_SCORE, data);

// Rating Scales
export const getRatingScalesAPI = () => axios.get(endpoints.RATING_SCALES);
export const deleteRatingScalesAPI = (data) => axios.delete(endpoints.RATING_SCALES, { data });
export const createRatingScalesAPI = (data) => axios.post(endpoints.RATING_SCALES, data);
export const modifyRatingScalesAPI = (data) => axios.put(endpoints.RATING_SCALES, data);

// Types of Benefits
export const getTypesOfBenefitsAPI = () => axios.get(endpoints.TYPES_OF_BENEFITS);
export const deleteTypesOfBenefitsAPI = (data) => axios.delete(endpoints.TYPES_OF_BENEFITS, { data });
export const createTypesOfBenefitsAPI = (data) => axios.post(endpoints.TYPES_OF_BENEFITS, data);
export const modifyTypesOfBenefitsAPI = (data) => axios.put(endpoints.TYPES_OF_BENEFITS, data);

// Recognition plans
export const getRecognitionPlansApi = () => axios.get(endpoints.RECOGNITION_PLANS_ENDPOINT);
export const createRecognitionPlanApi = (data) => axios.post(endpoints.RECOGNITION_PLANS_ENDPOINT, data);
export const updateRecognitionPlanApi = (data) => axios.put(endpoints.RECOGNITION_PLANS_ENDPOINT, data);
export const deleteRecognitionPlanAPI = (data) => axios.delete(endpoints.RECOGNITION_PLANS_ENDPOINT, { data });
export const duplicateRecognitionPlanApi = (data) => axios.post(endpoints.RECOGNITION_PLANS_DUPLICATE_ENDPOINT, data);

// Benefits for plans
export const getBenefitsForPlansAPI = () => axios.get(endpoints.BENEFITS_FOR_PLANS);
export const deleteBenefitsForPlansAPI = (data) => axios.delete(endpoints.BENEFITS_FOR_PLANS, { data });
export const createBenefitsForPlansAPI = (data) => axios.post(endpoints.BENEFITS_FOR_PLANS, data);
export const modifyBenefitsForPlansAPI = (data) => axios.put(endpoints.BENEFITS_FOR_PLANS, data);

// Organizational benefits
export const getOrganizationalBenefitsAPI = () => axios.get(endpoints.ORGANIZATIONAL_BENEFITS);
export const deleteOrganizationalBenefitsAPI = (data) => axios.delete(endpoints.ORGANIZATIONAL_BENEFITS, { data });
export const createOrganizationalBenefitsAPI = (data) => axios.post(endpoints.ORGANIZATIONAL_BENEFITS, data);
export const modifyOrganizationalBenefitsAPI = (data) => axios.put(endpoints.ORGANIZATIONAL_BENEFITS, data);

//Feedback purpose
export const getFeedbackPurposeAPI = () => axios.get(endpoints.FEEDBACK_PURPOSE_ENDPOINT);
export const createFeedbackPurposeAPI = (data) => axios.post(endpoints.FEEDBACK_PURPOSE_ENDPOINT, data);
export const updateFeedbackPurposeAPI = (data) => axios.put(endpoints.FEEDBACK_PURPOSE_ENDPOINT, data);
export const deleteFeedbackPurposeAPI = (data) => axios.delete(endpoints.FEEDBACK_PURPOSE_ENDPOINT, { data });

// Group classification
export const getGroupClassificationAPI = () => axios.get(endpoints.GROUP_CLASSIFICATION_ENDPOINT);
export const createGroupClassificationAPI = (data) => axios.post(endpoints.GROUP_CLASSIFICATION_ENDPOINT, data);
export const updateGroupClassificationAPI = (data) => axios.put(endpoints.GROUP_CLASSIFICATION_ENDPOINT, data);
export const deleteGroupClassificationAPI = (data) => axios.delete(endpoints.GROUP_CLASSIFICATION_ENDPOINT, { data });

// reasonForPointAwarding
export const getReasonForPointAwardingApi = () => axios.get(endpoints.REASON_FOR_POINT_AWARDING_ENDPOINT);
export const createReasonForPointAwardingApi = (data) => axios.post(endpoints.REASON_FOR_POINT_AWARDING_ENDPOINT, data);
export const updateReasonForPointAwardingApi = (data) => axios.put(endpoints.REASON_FOR_POINT_AWARDING_ENDPOINT, data);
export const deleteReasonForPointAwardingAPI = (data) => axios.delete(endpoints.REASON_FOR_POINT_AWARDING_ENDPOINT, { data });

// medal table
export const getMedalTableAPI = (query) => axios.get(endpoints.MEDAL_TABLE_ENDPOINT + query);
export const updateMedalTableAPI = (data) => axios.post(endpoints.MEDAL_TABLE_ENDPOINT, data);
export const updateMedalTableAdjustmentAPI = (data) => axios.post(endpoints.MEDAL_TABLE_ADJUSTMENT_ENDPOINT, data);

// Recognition plans assignment
export const getRecognitionPlansAssignmentAPI = (query) => axios.get(endpoints.RECOGNITION_PLANS_ASSIGNMENT_ENDPOINT + query);
export const changeCollaboratorsPlanApi = (data) => axios.put(endpoints.RECOGNITION_PLANS_ASSIGNMENT_CHANGE_PLAN_ENDPOINT, data);
export const createRecognitionPlansAssignmentAPI = (data) => axios.post(endpoints.RECOGNITION_PLANS_ASSIGNMENT_ENDPOINT, data);
export const updateRecognitionPlansAssignmentAPI = (data) => axios.put(endpoints.RECOGNITION_PLANS_ASSIGNMENT_ACTIVATION_ENDPOINT, data);
export const deleteRecognitionPlansAssignmentAPI = (data) => axios.delete(endpoints.RECOGNITION_PLANS_ASSIGNMENT_ENDPOINT, { data });

// Performance forms 
export const getPerformanceFormsAPI = () => axios.get(endpoints.PERFORMANCE_FORMS_ENDPOINT);
export const updatePerformanceFormsAPI = (data) => axios.put(endpoints.PERFORMANCE_FORMS_ENDPOINT, data);
export const createPerformanceFormsAPI = (data) => axios.post(endpoints.PERFORMANCE_FORMS_ENDPOINT, data);
export const duplicatePerformanceFormsAPI = (data) => axios.post(endpoints.PERFORMANCE_FORMS_DUPLICATE_ENDPOINT, data);
export const deletePerformanceFormsAPI = (data) => axios.delete(endpoints.PERFORMANCE_FORMS_ENDPOINT, { data });

// Performance evaluations 
export const getInitialPerformanceEvaluationsAPI = (query) => axios.get(endpoints.PERFORMANCE_EVALUATIONS_INITIAL_ENDPOINT + query);
export const getResumePerformanceEvaluationsAPI = () => axios.get(endpoints.PERFORMANCE_EVALUATIONS_RESUME_ENDPOINT);
export const getPerformanceEvaluationsAPI = (query) => axios.get(endpoints.PERFORMANCE_EVALUATIONS_ENDPOINT + query);
export const createPerformanceEvaluationsAPI = (data) => axios.post(endpoints.PERFORMANCE_EVALUATIONS_ENDPOINT, data);

// Performance evaluations calibration
export const getInitialPerformanceEvaluationsCalibrationAPI = () => axios.get(endpoints.PERFORMANCE_EVALUATIONS_CALIBRATION_INITIAL_ENDPOINT);
export const getListPerformanceEvaluationsCalibrationAPI = (data) => axios.post(endpoints.PERFORMANCE_EVALUATIONS_CALIBRATION_LIST_ENDPOINT, data);
export const getGraphicsPerformanceEvaluationsCalibrationAPI = (data) => axios.post(endpoints.PERFORMANCE_EVALUATIONS_CALIBRATION_GRAPHICS_ENDPOINT, data);
export const updateSkillsPerformanceEvaluationsCalibrationAPI = (data) => axios.put(endpoints.PERFORMANCE_EVALUATIONS_CALIBRATION_SKILLS_ENDPOINT, data);
export const getFormPerformanceEvaluationsCalibrationAPI = (query) => axios.get(endpoints.PERFORMANCE_EVALUATIONS_CALIBRATION_FORM_ENDPOINT + query);
export const updateFormPerformanceEvaluationsCalibrationAPI = (data) => axios.post(endpoints.PERFORMANCE_EVALUATIONS_CALIBRATION_FORM_ENDPOINT, data);
export const sendMailCalibrationAPI = (data) => axios.post(endpoints.PERFORMANCE_EVALUATIONS_CALIBRATION_MAIL_ENDPOINT, data);

// Performance Summary and Results 
export const getPerformanceSummaryAndResultsAPI = () => axios.get(endpoints.PERFORMANCE_SUMMARY_RESULTS_ENDPOINT);
export const getSummaryAPI = (query) => axios.get(endpoints.PERFORMANCE_SUMMARY_PERSONAL_RESULTS_ENDPOINT + query);
export const acceptRejectPerformanceSummaryAndResultsAPI = (data) => axios.post(endpoints.ACCEPT_REJECT_PERFORMANCE_SUMMARY_RESULTS_ENDPOINT, data);
export const previewSummaryAndResultsAPI = (data) => axios.post(endpoints.PREVIEW_PERFORMANCE_SUMMARY_RESULTS_ENDPOINT, data, {
  responseType: "blob",
});

// Nine Box Settings
export const getNineBoxSettingsAPI = () => axios.get(endpoints.NINE_BOX_SETTINGS_ENDPOINT);
export const updateNineBoxSettingsAPI = (data) => axios.put(endpoints.NINE_BOX_SETTINGS_ENDPOINT, data);
export const orderNineBoxSettingsAPI = (data) => axios.put(endpoints.NINE_BOX_SETTINGS_ORDER_ENDPOINT, data);


// my benefits
export const getMyBenefitsAPI = () => axios.get(endpoints.MY_BENEFITS_ENDPOINT);
export const requestMyBenefitAPI = (data) => axios.post(endpoints.REQUEST_MY_BENEFITS_ENDPOINT, data);
export const getMyBenefitsHistoricalAPI = () => axios.get(endpoints.MY_BENEFITS_HISTORICAL_ENDPOINT);

//Recognition Analysis
export const getRecognitionAnalysisAPI = (query) => axios.get(endpoints.RECOGNITION_ANALYSIS_ENDPOINT + query);

// Action Type Settings
export const getActionTypesAPI = () => axios.get(endpoints.ACTION_TYPES_ENDPOINT);
export const createActionTypeAPI = (data) => axios.post(endpoints.ACTION_TYPES_ENDPOINT, data);
export const updateActionTypeAPI = (data) => axios.put(endpoints.ACTION_TYPES_ENDPOINT, data);
export const deleteActionTypeAPI = (data) => axios.delete(endpoints.ACTION_TYPES_ENDPOINT, { data });

// Poll comparison
export const getPollComparisonAPI = () => axios.get(endpoints.POLL_COMPARISON_ENDPOINT);
export const getPollComparisonQuestionsAPI = (query) => axios.get(endpoints.POLL_COMPARISON_QUESTIONS_ENDPOINT + query);
export const getPollComparisonAnswersAPI = (query) => axios.get(endpoints.POLL_COMPARISON_ANSWERS_ENDPOINT + query);

// feed
export const getFeedInitialDataAPI = () => axios.get(endpoints.FEED_INITIAL_ENDPOINT);
export const getFeedPublicationsAPI = (query) => axios.get(endpoints.FEED_ENDPOINT + query);
export const createFeedPublicationAPI = (data) => axios.post(endpoints.FEED_ENDPOINT, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const updateFeedPublicationAPI = (data) => axios.put(endpoints.FEED_ENDPOINT, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const deleteFeedPublicationAPI = (data) => axios.delete(endpoints.FEED_ENDPOINT, { data });
export const changeFeedPublicationStatusAPI = (data) => axios.put(endpoints.FEED_STATUS_ENDPOINT, data);

// feed comments
export const getFeedPublicationCommentsAPI = (query) => axios.get(endpoints.FEED_COMMENTS_ENDPOINT + query);
export const createFeedPublicationCommentAPI = (data) => axios.post(endpoints.FEED_COMMENTS_ENDPOINT, data);
export const updateFeedPublicationCommentAPI = (data) => axios.put(endpoints.FEED_COMMENTS_ENDPOINT, data);
export const deleteFeedPublicationCommentAPI = (data) => axios.delete(endpoints.FEED_COMMENTS_ENDPOINT, { data });
export const changeFeedPublicationCommentStatusAPI = (data) => axios.put(endpoints.FEED_COMMENTS_STATUS_ENDPOINT, data);

// feed participation and likes
export const indicateFeedPublicationParticipationAPI = (data) => axios.put(endpoints.FEED_PARTICIPATION_ENDPOINT, data);
export const likeFeedPublicationAPI = (data) => axios.put(endpoints.FEED_LIKES_ENDPOINT, data);
export const downloadFeedPublicationParticipationAPI = (data) => axios.post(endpoints.FEED_PARTICIPATION_DOWNLOAD_ENDPOINT, data, {
  responseType: "blob",
});

// feedback
export const getFeedbackAPI = (query) => axios.get(endpoints.FEEDBACK_ENDPOINT + query);
export const getFeedbackPrivacyOptionsAPI = () => axios.get(endpoints.FEEDBACK_PRIVACY_OPTIONS_ENDPOINT);
export const getFeedbackPublicationsAPI = (data) => axios.post(endpoints.FEEDBACK_FILTER_ENDPOINT, data);
export const createFeedbackPublicationAPI = (data) => axios.post(endpoints.FEEDBACK_ENDPOINT, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const updateFeedbackPublicationAPI = (data) => axios.put(endpoints.FEEDBACK_ENDPOINT, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const deleteFeedbackPublicationAPI = (data) => axios.delete(endpoints.FEEDBACK_ENDPOINT, { data });
export const getFeedbackGoalsAPI = (query) => axios.get(endpoints.FEEDBACK_GOALS_ENDPOINT + query);

// feed comments
export const getFeedbackPublicationCommentsAPI = (query) => axios.get(endpoints.FEEDBACK_COMMENTS_ENDPOINT + query);
export const createFeedbackPublicationCommentAPI = (data) => axios.post(endpoints.FEEDBACK_COMMENTS_ENDPOINT, data);
export const updateFeedbackPublicationCommentAPI = (data) => axios.put(endpoints.FEEDBACK_COMMENTS_ENDPOINT, data);
export const deleteFeedbackPublicationCommentAPI = (data) => axios.delete(endpoints.FEEDBACK_COMMENTS_ENDPOINT, { data });

// feed participation and likes
export const likeFeedbackPublicationAPI = (data) => axios.put(endpoints.FEEDBACK_LIKES_ENDPOINT, data);

// user menus
export const getUserMenusAPI = () => axios.get(endpoints.USER_MENUS_ENDPOINT);
export const createUserMenusAPI = (data) => axios.post(endpoints.USER_MENUS_ENDPOINT, data);
export const updateUserMenusAPI = (data) => axios.put(endpoints.USER_MENUS_ENDPOINT, data);
export const deleteUserMenusAPI = (data) => axios.delete(endpoints.USER_MENUS_ENDPOINT, { data });

// social blacklist
export const getSocialBlacklistAPI = () => axios.get(endpoints.SOCIAL_BLACKLIST_ENDPOINT);
export const createSocialBlacklistAPI = (data) => axios.post(endpoints.SOCIAL_BLACKLIST_ENDPOINT, data);
export const deleteSocialBlacklistAPI = (data) => axios.delete(endpoints.SOCIAL_BLACKLIST_ENDPOINT, { data });

// kpi
export const getKPIDataAPI = (query) => axios.get(endpoints.GET_KPI_INDICATOR_ENDPOINT + query);

// Canal de comunicaciones
export const getCommunicationChannelAPI = (query) => axios.get(endpoints.COMMUNICATION_CHANNEL_ENDPOINT + query);
export const createCommunicationChannelAPI = (data) => axios.post(endpoints.COMMUNICATION_CHANNEL_ENDPOINT, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const updateCommunicationChannelAPI = (data) => axios.put(endpoints.COMMUNICATION_CHANNEL_ENDPOINT, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const deleteCommunicationChannelAPI = (data) => axios.delete(endpoints.COMMUNICATION_CHANNEL_ENDPOINT, { data });
export const answerCommunicationChannelAPI = (data) => axios.post(endpoints.ANSWER_COMMUNICATION_CHANNEL_ENDPOINT, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const assignResponsibleCommunicationChannelAPI = (data) => axios.post(endpoints.ASSIGN_RESPONSIBLE_COMMUNICATION_CHANNEL_ENDPOINT, data);
export const classifyCommunicationChannelAPI = (data) => axios.put(endpoints.CLASSIFY_COMMUNICATION_CHANNEL_ENDPOINT, data);

// types of objectives
export const getTypesOfObjectivesAPI = () => axios.get(endpoints.TYPES_OF_OBJECTIVES_ENDPOINT);
export const createTypeOfObjectivesAPI = (data) => axios.post(endpoints.TYPES_OF_OBJECTIVES_ENDPOINT, data);
export const updateTypeOfObjectivesAPI = (data) => axios.put(endpoints.TYPES_OF_OBJECTIVES_ENDPOINT, data);
export const deleteTypeOfObjectivesAPI = (data) => axios.delete(endpoints.TYPES_OF_OBJECTIVES_ENDPOINT, { data });

// positionGroupsForEvaluations
export const getPositionGroupsForEvaluationsAPI = () => axios.get(endpoints.POSITION_GROUPS_FOR_EVALUATIONS_ENDPOINT);
export const createPositionGroupsForEvaluationsAPI = (data) => axios.post(endpoints.POSITION_GROUPS_FOR_EVALUATIONS_ENDPOINT, data);
export const updatePositionGroupsForEvaluationsAPI = (data) => axios.put(endpoints.POSITION_GROUPS_FOR_EVALUATIONS_ENDPOINT, data);
export const deletePositionGroupsForEvaluationsAPI = (data) => axios.delete(endpoints.POSITION_GROUPS_FOR_EVALUATIONS_ENDPOINT, { data });

// periodsForObjectives
export const getPeriodsForObjectivesAPI = () => axios.get(endpoints.PERIODS_FOR_OBJECTIVES_ENDPOINT);
export const createPeriodForObjectivesAPI = (data) => axios.post(endpoints.PERIODS_FOR_OBJECTIVES_ENDPOINT, data);
export const updatePeriodForObjectivesAPI = (data) => axios.put(endpoints.PERIODS_FOR_OBJECTIVES_ENDPOINT, data);
export const deletePeriodForObjectivesAPI = (data) => axios.delete(endpoints.PERIODS_FOR_OBJECTIVES_ENDPOINT, { data });

// types of skills transversal
export const getTypesOfSkillsTransversalAPI = () => axios.get(endpoints.TYPES_OF_SKILLS_TRANSVERSAL_ENDPOINT);
export const createTypeOfSkillsTransversalAPI = (data) => axios.post(endpoints.TYPES_OF_SKILLS_TRANSVERSAL_ENDPOINT, data);
export const updateTypeOfSkillsTransversalAPI = (data) => axios.put(endpoints.TYPES_OF_SKILLS_TRANSVERSAL_ENDPOINT, data);
export const deleteTypeOfSkillsTransversalAPI = (data) => axios.delete(endpoints.TYPES_OF_SKILLS_TRANSVERSAL_ENDPOINT, { data });
export const duplicateTypeOfSkillsTransversalAPI = (data) => axios.post(endpoints.TYPES_OF_SKILLS_TRANSVERSAL_DUPLICATE_ENDPOINT, data);

// types of skills transversal indicators
export const getTypesOfSkillsTransversalIndicatorsAPI = (query) => axios.get(endpoints.TYPES_OF_SKILLS_TRANSVERSAL_INDICATORS_ENDPOINT + query);
export const createTypeOfSkillsTransversalIndicatorAPI = (data) => axios.post(endpoints.TYPES_OF_SKILLS_TRANSVERSAL_INDICATOR_ENDPOINT, data);
export const updateTypeOfSkillsTransversalIndicatorAPI = (data) => axios.put(endpoints.TYPES_OF_SKILLS_TRANSVERSAL_INDICATOR_ENDPOINT, data);
export const deleteTypeOfSkillsTransversalIndicatorAPI = (data) => axios.delete(endpoints.TYPES_OF_SKILLS_TRANSVERSAL_INDICATOR_ENDPOINT, { data });

// types of skills specific
export const getTypesOfSkillsSpecificAPI = () => axios.get(endpoints.TYPES_OF_SKILLS_SPECIFIC_ENDPOINT);
export const createTypeOfSkillsSpecificAPI = (data) => axios.post(endpoints.TYPES_OF_SKILLS_SPECIFIC_ENDPOINT, data);
export const updateTypeOfSkillsSpecificAPI = (data) => axios.put(endpoints.TYPES_OF_SKILLS_SPECIFIC_ENDPOINT, data);
export const deleteTypeOfSkillsSpecificAPI = (data) => axios.delete(endpoints.TYPES_OF_SKILLS_SPECIFIC_ENDPOINT, { data });
export const duplicateTypeOfSkillsSpecificAPI = (data) => axios.post(endpoints.TYPES_OF_SKILLS_SPECIFIC_DUPLICATE_ENDPOINT, data);
export const duplicateGroupTypeOfSkillsSpecificAPI = (data) => axios.post(endpoints.TYPES_OF_SKILLS_SPECIFIC_DUPLICATE_GROUP_ENDPOINT, data);
export const verifyTypeOfSkillsSpecificAPI = (data) => axios.post(endpoints.TYPES_OF_SKILLS_SPECIFIC_VERIFY_ENDPOINT, data);

// types of skills specific indicators
export const getTypesOfSkillsSpecificIndicatorsAPI = (query) => axios.get(endpoints.TYPES_OF_SKILLS_SPECIFIC_INDICATORS_ENDPOINT + query);
export const createTypeOfSkillsSpecificIndicatorAPI = (data) => axios.post(endpoints.TYPES_OF_SKILLS_SPECIFIC_INDICATOR_ENDPOINT, data);
export const updateTypeOfSkillsSpecificIndicatorAPI = (data) => axios.put(endpoints.TYPES_OF_SKILLS_SPECIFIC_INDICATOR_ENDPOINT, data);
export const deleteTypeOfSkillsSpecificIndicatorAPI = (data) => axios.delete(endpoints.TYPES_OF_SKILLS_SPECIFIC_INDICATOR_ENDPOINT, { data });

// goals and objectives
export const getGoalsAndObjectivesAPI = (query) => axios.get(endpoints.GOALS_AND_OBJECTIVES_ENDPOINT + query);
export const createGoalsAndObjectivesAPI = (data) => axios.post(endpoints.GOALS_AND_OBJECTIVES_ENDPOINT, data);
export const modifyGoalsAndObjectivesAPI = (data) => axios.put(endpoints.GOALS_AND_OBJECTIVES_ENDPOINT, data);
export const deleteGoalsAndObjectivesAPI = (data) => axios.delete(endpoints.GOALS_AND_OBJECTIVES_ENDPOINT, { data });
export const verifyGoalsAndObjectivesAPI = (data) => axios.post(endpoints.GOALS_AND_OBJECTIVES_VERIFY_ENDPOINT, data);
export const getGoalsAndObjectivesTasksAPI = (query) => axios.get(endpoints.GOALS_AND_OBJECTIVES_TASKS_ENDPOINT + query);
export const getGoalsAndObjectivesOneAPI = (query) => axios.get(endpoints.GOALS_AND_OBJECTIVES_ONE_ENDPOINT + query);

// Evaluation process definition
export const getEvaluationProcessDefinitionAPI = () => axios.get(endpoints.EVALUATION_PROCESS_DEFINITION_ENDPOINT);
export const createEvaluationProcessDefinitionAPI = (data) => axios.post(endpoints.EVALUATION_PROCESS_DEFINITION_ENDPOINT, data);
export const updateEvaluationProcessDefinitionAPI = (data) => axios.put(endpoints.EVALUATION_PROCESS_DEFINITION_ENDPOINT, data);
export const deleteEvaluationProcessDefinitionAPI = (data) => axios.delete(endpoints.EVALUATION_PROCESS_DEFINITION_ENDPOINT, { data });
export const duplicateEvaluationProcessDefinitionAPI = (data) => axios.post(endpoints.EVALUATION_PROCESS_DEFINITION_DUPLICATE_ENDPOINT, data);
export const getEvaluationProcessDefinitionCheckCollaboratorsAPI = (query) => axios.get(endpoints.CHECK_COLLABORATORS_EVALUATION_PROCESS_DEFINITION_ENDPOINT + query);
export const sendMailEvaluationProcessDefinitionAPI = (data) => axios.post(endpoints.SEND_MAIL_EVALUATION_PROCESS_DEFINITION_ENDPOINT, data);

// Evaluation process definition assignment
export const getEvaluationProcessDefinitionAssignmentAPI = (query) => axios.get(endpoints.EVALUATION_PROCESS_DEFINITION_ASSIGNMENT_ENDPOINT + query);
export const createEvaluationProcessDefinitionAssignmentAPI = (data) => axios.post(endpoints.EVALUATION_PROCESS_DEFINITION_ASSIGNMENT_ENDPOINT, data);
export const deleteEvaluationProcessDefinitionAssignmentAPI = (data) => axios.delete(endpoints.EVALUATION_PROCESS_DEFINITION_ASSIGNMENT_ENDPOINT, { data });
export const downloadEvaluationProcessDefinitionAssignmentAPI = (data) => axios.post(endpoints.DOWNLOAD_EVALUATION_PROCESS_DEFINITION_ASSIGNMENT_ENDPOINT, data, {
  responseType: "blob",
});
export const uploadEvaluationProcessDefinitionAssignmentAPI = (data) => axios.post(endpoints.UPLOAD_EVALUATION_PROCESS_DEFINITION_ASSIGNMENT_ENDPOINT, data, {
  headers: {
    contentType: 'multipart/form-data',
  },
  responseType: "blob",
});
export const getEvaluatorsForEvaluationProcessDefinitionAssignmentAPI = (data) => axios.post(endpoints.EVALUATORS_LIST_EVALUATION_PROCESS_DEFINITION_ASSIGNMENT_ENDPOINT, data);
export const setEvaluatorForEvaluationProcessDefinitionAssignmentAPI = (data) => axios.post(endpoints.SET_EVALUATOR_EVALUATION_PROCESS_DEFINITION_ASSIGNMENT_ENDPOINT, data);

// performance analytics evaluation
export const getPerformanceAnalyticsEvaluationsAPI = () => axios.get(endpoints.PERFORMANCE_ANALYTICS_EVALUATION_ENDPOINT);
export const getPerformanceAnalyticsEvaluationsScoreAPI = (query) => axios.get(endpoints.PERFORMANCE_ANALYTICS_EVALUATION_SCORE_ENDPOINT + query);
export const getPerformanceAnalyticsEvaluationsSummaryAPI = (data) => axios.post(endpoints.PERFORMANCE_ANALYTICS_EVALUATION_SUMMARY_ENDPOINT, data);

// performance analytics talent
export const getPerformanceAnalyticsTalentAPI = () => axios.get(endpoints.PERFORMANCE_ANALYTICS_TALENT_ENDPOINT);
export const getPerformanceAnalyticsTalentQuadrantAPI = (query) => axios.get(endpoints.PERFORMANCE_ANALYTICS_TALENT_QUADRANT_ENDPOINT + query);
export const getPerformanceAnalyticsTalentSummaryAPI = (data) => axios.post(endpoints.PERFORMANCE_ANALYTICS_TALENT_SUMMARY_ENDPOINT, data);

// communication Classification
export const getCommunicationClassificationAPI = () => axios.get(endpoints.COMMUNICATION_CLASSIFICATION_ENDPOINT);
export const deleteCommunicationClassificationAPI = (data) => axios.delete(endpoints.COMMUNICATION_CLASSIFICATION_ENDPOINT, { data });
export const createCommunicationClassificationAPI = (data) => axios.post(endpoints.COMMUNICATION_CLASSIFICATION_ENDPOINT, data);
export const modifyCommunicationClassificationAPI = (data) => axios.put(endpoints.COMMUNICATION_CLASSIFICATION_ENDPOINT, data);

// ClassificationApplicantsTypes
export const getClassificationApplicantsTypesAPI = () => axios.get(endpoints.CLASSIFICATION_APPLICANTS_TYPES_ENDPOINT);
export const deleteClassificationApplicantsTypesAPI = (data) => axios.delete(endpoints.CLASSIFICATION_APPLICANTS_TYPES_ENDPOINT, { data });
export const createClassificationApplicantsTypesAPI = (data) => axios.post(endpoints.CLASSIFICATION_APPLICANTS_TYPES_ENDPOINT, data);
export const modifyClassificationApplicantsTypesAPI = (data) => axios.put(endpoints.CLASSIFICATION_APPLICANTS_TYPES_ENDPOINT, data);

// TypesOfPublicationChannels
export const getTypesOfPublicationChannelsAPI = () => axios.get(endpoints.TYPES_OF_PUBLICATION_CHANNELS);
export const deleteTypesOfPublicationChannelsAPI = (data) => axios.delete(endpoints.TYPES_OF_PUBLICATION_CHANNELS, { data });
export const createTypesOfPublicationChannelsAPI = (data) => axios.post(endpoints.TYPES_OF_PUBLICATION_CHANNELS, data);
export const modifyTypesOfPublicationChannelsAPI = (data) => axios.put(endpoints.TYPES_OF_PUBLICATION_CHANNELS, data);

// TypesOfPublicationFormats
export const getTypesOfPublicationFormatsAPI = () => axios.get(endpoints.TYPES_OF_PUBLICATION_FORMATS);
export const deleteTypesOfPublicationFormatsAPI = (data) => axios.delete(endpoints.TYPES_OF_PUBLICATION_FORMATS, { data });
export const createTypesOfPublicationFormatsAPI = (data) => axios.post(endpoints.TYPES_OF_PUBLICATION_FORMATS, data);
export const modifyTypesOfPublicationFormatsAPI = (data) => axios.put(endpoints.TYPES_OF_PUBLICATION_FORMATS, data);

// TypesOfOnboardingActions
export const getTypesOfOnboardingActionsAPI = () => axios.get(endpoints.TYPES_OF_ONBOARDING_ACTIONS);
export const deleteTypesOfOnboardingActionsAPI = (data) => axios.delete(endpoints.TYPES_OF_ONBOARDING_ACTIONS, { data });
export const createTypesOfOnboardingActionsAPI = (data) => axios.post(endpoints.TYPES_OF_ONBOARDING_ACTIONS, data);
export const modifyTypesOfOnboardingActionsAPI = (data) => axios.put(endpoints.TYPES_OF_ONBOARDING_ACTIONS, data);

// Onboarding profiles
export const getOnboardingProfilesAPI = () => axios.get(endpoints.ONBOARDING_PROFILES_ENDPOINT);
export const updateOnboardingProfilesAPI = (data) => axios.put(endpoints.ONBOARDING_PROFILES_ENDPOINT, data);
export const createOnboardingProfilesAPI = (data) => axios.post(endpoints.ONBOARDING_PROFILES_ENDPOINT, data);
export const duplicateOnboardingProfilesAPI = (data) => axios.post(endpoints.ONBOARDING_PROFILES_DUPLICATE_ENDPOINT, data);
export const deleteOnboardingProfilesAPI = (data) => axios.delete(endpoints.ONBOARDING_PROFILES_ENDPOINT, { data });
export const getMaterialTreeAPI = (data) => axios.post(endpoints.ONBOARDING_PROFILES_TREE_VIEW_ENDPOINT, data);

// Onboarding profiles
export const getOnboardingMaterialsAPI = () => axios.get(endpoints.ONBOARDING_MATERIALS_ENDPOINT);
export const createOnboardingMaterialAPI = (data) => axios.post(endpoints.ONBOARDING_MATERIALS_ENDPOINT, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const updateOnboardinMaterialAPI = (data) => axios.put(endpoints.ONBOARDING_MATERIALS_ENDPOINT, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const deleteOnboardingMaterialAPI = (data) => axios.delete(endpoints.ONBOARDING_MATERIALS_ENDPOINT, { data });

// utils
export const getUtilsAPI = () => axios.get(endpoints.UTILS_ENDPOINT);


// Onboarding processes
export const getOnboardingProcessesAPI = () => axios.get(endpoints.ONBOARDING_PROCESSES_ENDPOINT);
export const createOnboardingProcessAPI = (data) => axios.post(endpoints.ONBOARDING_PROCESSES_ENDPOINT, data);
export const updateOnboardingProcessAPI = (data) => axios.put(endpoints.ONBOARDING_PROCESSES_ENDPOINT, data);
export const deleteOnboardingProcessAPI = (data) => axios.delete(endpoints.ONBOARDING_PROCESSES_ENDPOINT, { data });

// onboarding process definition assignment
export const getOnboardingProcessAssignmentAPI = (query) => axios.get(endpoints.ONBOARDING_PROCESSES_ASSIGNMENT_ENDPOINT + query);
export const createOnboardingProcessAssignmentAPI = (data) => axios.post(endpoints.ONBOARDING_PROCESSES_ASSIGNMENT_ENDPOINT, data);
export const deleteOnboardingProcessAssignmentAPI = (data) => axios.delete(endpoints.ONBOARDING_PROCESSES_ASSIGNMENT_ENDPOINT, { data });

// onboarding tracking
export const getOnboardingTrackingAPI = () => axios.get(endpoints.ONBOARDING_TRACKING_ENDPOINT);
export const getMomentsOnboardingTrackingAPI = (query) => axios.get(endpoints.ONBOARDING_TRACKING_MOMENTS_ENDPOINT + query);
export const getProgressOnboardingTrackingAPI = (query) => axios.get(endpoints.ONBOARDING_TRACKING_PROGRESS_ENDPOINT + query);
export const getTreeOnboardingTrackingAPI = (query) => axios.get(endpoints.ONBOARDING_TRACKING_TREE_ENDPOINT + query);
export const getActionsOnboardingTrackingAPI = (query) => axios.get(endpoints.ONBOARDING_TRACKING_ACTIONS_ENDPOINT + query);
export const updateActionStatusOnboardingTrackingAPI = (data) => axios.put(endpoints.ONBOARDING_TRACKING_ACTIONS_ENDPOINT, data);
export const updateIndividualActionOnboardingTrackingAPI = (data) => axios.put(endpoints.ONBOARDING_TRACKING_ACTION_ENDPOINT, data);
export const deleteActionOnboardingTrackingAPI = (data) => axios.delete(endpoints.ONBOARDING_TRACKING_ACTION_ENDPOINT, { data });
export const replicateActionOnboardingTrackingAPI = (data) => axios.put(endpoints.ONBOARDING_TRACKING_ACTION_REPLICATE_ENDPOINT, data);
export const assignActionResponsibleOnboardingTrackingAPI = (data) => axios.put(endpoints.ONBOARDING_TRACKING_ACTION_RESPONSIBLE_ENDPOINT, data);
export const getCollaboratorsOnboardingTrackingAPI = (query) => axios.get(endpoints.ONBOARDING_TRACKING_COLLABORATORS_ENDPOINT + query);
export const addCollaboratorOnboardingTrackingAPI = (data) => axios.post(endpoints.ONBOARDING_TRACKING_COLLABORATOR_ENDPOINT, data);
export const changeCollaboratorStatusOnboardingTrackingAPI = (data) => axios.put(endpoints.ONBOARDING_TRACKING_COLLABORATOR_ENDPOINT, data);
export const deleteCollaboratorOnboardingTrackingAPI = (data) => axios.delete(endpoints.ONBOARDING_TRACKING_COLLABORATOR_ENDPOINT, { data });

// onboarding tracking comments
export const getOnboardingTrackingActionCommentsAPI = (query) => axios.get(endpoints.ONBOARDING_TRACKING_COMMENTS_ENDPOINT + query);
export const createOnboardingTrackingActionCommentsAPI = (data) => axios.post(endpoints.ONBOARDING_TRACKING_COMMENTS_ENDPOINT, data);
export const updateOnboardingTrackingActionCommentsAPI = (data) => axios.put(endpoints.ONBOARDING_TRACKING_COMMENTS_ENDPOINT, data);
export const deleteOnboardingTrackingActionCommentsAPI = (data) => axios.delete(endpoints.ONBOARDING_TRACKING_COMMENTS_ENDPOINT, { data });

// onboarding tracking files
export const getOnboardingTrackingActionFilesAPI = (query) => axios.get(endpoints.ONBOARDING_TRACKING_FILES_ENDPOINT + query);
export const createOnboardingTrackingActionFileAPI = (data) => axios.post(endpoints.ONBOARDING_TRACKING_FILE_ENDPOINT, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const deleteOnboardingTrackingActionFileAPI = (data) => axios.delete(endpoints.ONBOARDING_TRACKING_FILE_ENDPOINT, { data });

// my onboarding tracking
export const getMyOnboardingTrackingAPI = () => axios.get(endpoints.MY_ONBOARDING_TRACKING_ENDPOINT);
export const getMomentsMyOnboardingTrackingAPI = (query) => axios.get(endpoints.MY_ONBOARDING_TRACKING_MOMENTS_ENDPOINT + query);
export const getProgressMyOnboardingTrackingAPI = (query) => axios.get(endpoints.MY_ONBOARDING_TRACKING_PROGRESS_ENDPOINT + query);
export const getActionsMyOnboardingTrackingAPI = (query) => axios.get(endpoints.MY_ONBOARDING_TRACKING_ACTIONS_ENDPOINT + query);
export const updateMaterialMyOnboardingTrackingAPI = (data) => axios.put(endpoints.MY_ONBOARDING_TRACKING_MATERIAL_ENDPOINT, data);

// onboarding tracking comments
export const getMyOnboardingTrackingActionCommentsAPI = (query) => axios.get(endpoints.MY_ONBOARDING_TRACKING_COMMENTS_ENDPOINT + query);
export const createMyOnboardingTrackingActionCommentsAPI = (data) => axios.post(endpoints.MY_ONBOARDING_TRACKING_COMMENTS_ENDPOINT, data);
export const updateMyOnboardingTrackingActionCommentsAPI = (data) => axios.put(endpoints.MY_ONBOARDING_TRACKING_COMMENTS_ENDPOINT, data);
export const deleteMyOnboardingTrackingActionCommentsAPI = (data) => axios.delete(endpoints.MY_ONBOARDING_TRACKING_COMMENTS_ENDPOINT, { data });

// onboarding tracking files
export const getMyOnboardingTrackingActionFilesAPI = (query) => axios.get(endpoints.MY_ONBOARDING_TRACKING_FILES_ENDPOINT + query);
export const createMyOnboardingTrackingActionFileAPI = (data) => axios.post(endpoints.MY_ONBOARDING_TRACKING_FILE_ENDPOINT, data, {
  headers: {
    'contentType': 'multipart/form-data'
  }
});
export const deleteMyOnboardingTrackingActionFileAPI = (data) => axios.delete(endpoints.MY_ONBOARDING_TRACKING_FILE_ENDPOINT, { data });

// performance Analytics historic
export const getPerformanceAnalyticsHistoricAPI = (query) => axios.get(endpoints.PERFORMANCE_ANALYTICS_HISTORIC_ENDPOINT + query);