import io from 'socket.io-client'
import { API_DOMAIN, RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { openProgressAction, updateProgressAction } from "@icarius-common/circularProgress/actions";
import { getAlertsAction } from "@icarius-pages/alerts/actions";
import { getLocalizedString } from "@icarius-localization/strings";
import { logOutAction } from "@icarius-pages/login/actions";
import paths from "@icarius-localization/paths";
import { previewFromURL, downloadFromURL } from "@icarius-utils/download";
import { getDocumentsRequestsAction } from "@icarius-pages/requestedDocuments/actions";
import { getCollaboratorsForCertificateAction } from "@icarius-pages/certificates/actions";
import { getColaboratorsForReceiptsAction } from "@icarius-pages/receipts/actions";
import { getDocumentsSignedAction } from "@icarius-pages/externalDocuments/actions";
import { SET_NEW_CONNECTED_USER, SET_NEW_DISCONNECTED_USER, SET_DIGITAL_DOCUMENT_QUANTITY } from 'src/app/actionTypes';
import { getCurrentProcessesAction } from "@icarius-pages/currentProcesses/actions";
import { QUERY_FULFILLED, QUERY_REJECTED } from '@icarius-common/gridProcessResultDialog/actionTypes';
let socket;
const ALERTS_ENABLED = false;

const reloadPage = (dispatch) => {
    console.log("Refresh due to abrupt socket disconnect")

    dispatch(openDialogAction({
        title: "Atención",
        msg: getLocalizedString("socketDisconnect"),
        acceptOnly: true,
        onConfirm: () => {
            window.location.replace("/");
        },
        onCancel: () => {
            window.location.replace("/");
        },
    }));
}

const showNotification = (dispatch, msg) => {
    dispatch(openSnackbarAction({
        msg: msg,
        duration: 5000,
        path: paths.alerts,
    }))
}

const updateProgress = (dispatch, current, total) => {
    dispatch(updateProgressAction(current * 100 / total));
}

const showDialog = (dispatch, msg, title, onConfirm, onCancel, acceptOnly) => {
    dispatch(openDialogAction({
        msg: msg,
        title: title,
        onConfirm: onConfirm,
        onCancel: onCancel,
        acceptOnly: acceptOnly
    }))
}

export const startConnection = (token, dispatch) => {
    socket = io.connect(API_DOMAIN, {
        transports: ["websocket"],		// Es muy importante agregar esto!
        allowUpgrades: false,
        reconnectionAttempts: 60,
        reconnectionDelay: 100,
        reconnectionDelayMax: 100,
        timeout: 2500,
        pingInterval: 25000,
        pingTimeout: 20000,
    });

    // Autentico la conexion enviando el token
    socket.on('connect', () => {
        socket.emit('authenticate', { token: token })
        console.log("Conectado")
    });

    socket.on('reconnect_failed', (e) => {
        console.log(e);
        reloadPage(dispatch);
    });
}

const handleNewAlertReceived = (data, dispatch) => {
    if (ALERTS_ENABLED) {
        showNotification(dispatch, `Tienes ${data.unread_alert_quantity} alerta/s sin leer`);
        dispatch(getAlertsAction());
    }
}

const handleUserConnected = (data, dispatch) => {
    dispatch({ type: SET_NEW_CONNECTED_USER, payload: data });
}

const handleUserDisconnected = (data, dispatch) => {
    dispatch({ type: SET_NEW_DISCONNECTED_USER, payload: data });
}

const handleUpdatedDocumentGenerationQuantity = (data, dispatch) => {
    if (data.generated_quantity === 0) {
        dispatch(openProgressAction());
    } else {
        updateProgress(dispatch, data.generated_quantity, data.total_quantity);
    }
}

const handleNewDocReceived = (data, dispatch) => {
    if (data?.docs_quantity) {
        dispatch({ type: SET_DIGITAL_DOCUMENT_QUANTITY, payload: data.docs_quantity });
    }
}

const handleUpdateStageQuantity = (data, dispatch) => {
    if (data.download) {
        let downloadData = data.download;

        //traer el listado de documentos a firmar actualizado (backend actualiza la lista cuando se firma)

        const currentUrl = window.location.href;

        if (currentUrl.includes(paths.requestedDocuments)) {
            dispatch(getDocumentsRequestsAction());
        }

        if (currentUrl.includes(paths.digitalCertificates)) {
            dispatch(getCollaboratorsForCertificateAction(downloadData.refs.code, downloadData.refs.reference, downloadData.refs.destRole));
        }

        if (currentUrl.includes(paths.digitalReceipts)) {
            dispatch(getColaboratorsForReceiptsAction(downloadData.refs.code, downloadData.refs.reference, false, downloadData.refs.destRole));
        }

        if (currentUrl.includes(paths.externalDocuments)) {
            dispatch(getDocumentsSignedAction());
        }

        previewFromURL(RESOURCES_ENDPOINT + downloadData.path);
        dispatch(openSnackbarAction({
            msg: `Documento generado: ${data.id}`,
            severity: "success",
        }))
    } else {
        const getSnackbarText = () => {
            if (data.stage === "d") return `${(data.stage_progress * 100 / data.stage_quantity).toFixed(2)}% - ${"Preparando la descarga..."}: ${data.id}`;
            if (data.stage === "g") {
                return `${(data.stage_progress * 100 / data.stage_quantity).toFixed(2)}% - ${data.stage_progress}/${data.stage_quantity} - ${"Generando documentos firmados"}: ${data.id}`;
            }
            return `${(data.stage_progress * 100 / data.stage_quantity).toFixed(2)}% - ${"Procesando"}: ${data.id}`;
        }

        dispatch(openSnackbarAction({
            msg: getSnackbarText(),
            severity: "info",
            duration: null,
        }))
    }
}

const handleUpdateCalcProcessMessage = (data, dispatch) => {
    const { status, message } = data;
    dispatch(openSnackbarAction({
        msg: message,
        severity: status === "0" ? "info" : status === "1" ? "success" : "error",
        duration: null
    }))

    if (message.includes("finalizado") && !message.includes("cálculo especial")) {
        dispatch(getCurrentProcessesAction());
    }
}

const handleDownloadDocument = (data, dispatch) => {
    if (data) {
        downloadFromURL(RESOURCES_ENDPOINT + data.path, data.name)
    }
}

// ejecución de proceso especial
const handleShowGrid = (data, dispatch) => {
    const {
        name,
        result,
        filters,
        date_format,
        currency_localization,
    } = data;

    if (!result) {
        dispatch({ type: QUERY_REJECTED });
        dispatch(openSnackbarAction({ msg: 'No se encontró información del proceso', severity: "warning" }));
    } else {
        dispatch({
            type: QUERY_FULFILLED,
            payload: {
                gridData: {
                    name,
                    result,
                    filters,
                    dateFormat: date_format,
                    currencyLocalization: currency_localization
                },
            }
        })
    }
}

const handleLogout = (data, dispatch) => {
    showDialog(
        dispatch,
        data.message,
        getLocalizedString("atention"),
        () => logOutAction(dispatch),
        undefined,
        true
    );
}

export const listenToSocket = (dispatch) => {
    socket.on('new_alert_received', (data) => handleNewAlertReceived(data, dispatch));
    socket.on('user_connected', (data) => handleUserConnected(data, dispatch));
    socket.on('user_disconnected', (data) => handleUserDisconnected(data, dispatch));
    socket.on('updated_document_generation_quantity', (data) => handleUpdatedDocumentGenerationQuantity(data, dispatch));
    socket.on('new_doc_received', (data) => handleNewDocReceived(data, dispatch));
    socket.on('updated_stage_quantity', (data) => handleUpdateStageQuantity(data, dispatch));
    socket.on('updated_calcprocess_message', (data) => handleUpdateCalcProcessMessage(data, dispatch));
    socket.on('download_document', (data) => handleDownloadDocument(data, dispatch));
    socket.on('show_grid', (data) => handleShowGrid(data, dispatch));
    socket.on('logout', (data) => handleLogout(data, dispatch));
}

export const stopListenToSocket = () => {
    socket.off('user_connected');
    socket.off('user_disconnected');
    socket.off('updated_document_generation_quantity');
    socket.off('new_doc_received');
    socket.off('updated_stage_quantity');
    socket.off('updated_calcprocess_message');
    socket.off('download_document');
    socket.off('show_grid');
}

export const stopConnection = () => {
    socket.disconnect();
}